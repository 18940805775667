import React from 'react';
import PropTypes from 'prop-types';

import Text, { SIZE_XS } from 'components/Text';
import SvgIcon from 'components/SvgIcon';
import { ReactComponent as ChevronDown } from 'assets/icons/icon-chevron-down.svg';

const DetailsButton = ({ episodesFocus, buttonOnClickHandler }) => {
    return (
        <div
            className={
                'details__button' +
                (episodesFocus ? ' details__button--previous' : ' details__button--next')
            }
            onClick={buttonOnClickHandler}
        >
            <Text size={SIZE_XS}>{episodesFocus ? 'Informations' : 'Épisodes'}</Text>
            <SvgIcon icon={ChevronDown} />
        </div>
    );
};

DetailsButton.propTypes = {
    buttonOnClickHandler: PropTypes.func,
    episodesFocus: PropTypes.bool
};

DetailsButton.defaultProps = {};

export default DetailsButton;
