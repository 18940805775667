import React, { Component /*, useEffect, useSelector, useState, createRef */ } from 'react';
// import { useHistory } from 'react-router-dom';
import { connect } from 'react-redux';
import Navigation, { Focusable, VerticalList, HorizontalList } from 'components/Navigation';

import { AuthContext } from 'contexts';
import Carousel from 'components/Carousel';
import Sidebar from 'components/SideBar';
import { goBack, goTo, isReturnEvent, PATH_HOME } from 'utils/NavigationUtils';
import { getFullCatalogNew } from 'redux/actions/CatalogActions';
import RowGroup from 'components/RowGroup';
import Loader from 'components/Loader';

/**
 * Page containing carousel, and lists of films
 */
class Catalog extends Component {
    constructor(props) {
        super(props);

        this.state = {
            activeList: null,
            carouselActive: true,
            showCatalog: false
        };

        this.carouselMouseEnter = this.carouselMouseEnter.bind(this);
        this.redirectTo = this.redirectTo.bind(this);
        this.setListActive = this.setListActive.bind(this);
        this.keyDownListener = this.keyDownListener.bind(this);

        this.rowsRef = [];
    }

    redirectTo(url) {
        goTo(this.props.history, url);
    }

    /**
     * Set carouselActive state and focus/blur actual visible carousel element
     * @param active
     */
    setCarouselActive(active) {
        this.setState({ carouselActive: active });

        if (this.carousel && this.carousel.carousel) {
            active
                ? this.carousel.carousel.carouselWrapperRef.focus()
                : this.carousel.carousel.carouselWrapperRef.blur();
        }
    }

    /**
     * Set activeList state to given index or null
     * @param index
     */
    setListActive(index = null) {
        this.setState({ activeList: index });
    }

    /**
     * Set focus on carousel on mouse enter event
     */
    carouselMouseEnter() {
        this.navigation.forceFocus(this.carouselFocusable.focusableId);
    }

    /**
     * Open sidebar on specified item focus
     * @param evnt
     */
    keyDownListener(evnt) {
        if (isReturnEvent(evnt) && this.sidebar && this.navigation && !this.sidebar.state.active) {
            this.sidebar.setActive(true);
            this.navigation.forceFocus(this.sidebar.menu.quit.item.focusableId);
            goTo(this.props.history, PATH_HOME, true);
        }
    }

    componentDidMount() {
        const { themes, themeActive, dataLoaded, lastFocus } = this.props;

        if (!dataLoaded) {
            this.props.getFullCatalog(themes[themeActive], this.context.user.NoCli);
        } else {
            this.setState({ showCatalog: true });
            if (this.props.sidebarExit) {
                if ( this.sidebar.menu.quit.item.focusableId ) this.navigation.forceFocus(this.sidebar.menu.quit.item.focusableId);
            } else {
                setTimeout(() => {
                    if ( lastFocus && lastFocus.length > 0 ) {
                        this.navigation.forceFocus(lastFocus);
                    } else if (this.carouselFocusable) {
                        this.navigation.forceFocus(this.carouselFocusable.focusableId);
                    } else {
                        this.navigation.forceFocus(
                            this?.rowsRef[0]?.list?.itemsRef[0]?.itemFocusable?.focusableId
                        );
                    }
                }, 300);
            }
        }

        window.addEventListener('keydown', this.keyDownListener);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.match.params.action === 'quit' && !nextProps.match.params.action) {
            return false;
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        const { themes, themeActive, dataLoaded } = this.props;

        // Fetch data from api if theme has changed in reducer
        if (prevProps.dataLoaded && !dataLoaded) {
            this.props.getFullCatalog(themes[themeActive], this.context.user.NoCli);
            this.setState({ showCatalog: false });
        } else if (!prevProps.dataLoaded && dataLoaded) {
            this.setState({ showCatalog: true });

            if (this.props.sidebarExit) {
                this.navigation.forceFocus(this.sidebar.menu.quit.item.focusableId);
            } else {
                setTimeout(() => {
                    if (this.carouselFocusable) {
                        this.navigation.forceFocus(this.carouselFocusable.focusableId);
                    } else {
                        this.navigation.forceFocus(
                            this?.rowsRef[0]?.list?.itemsRef[0]?.itemFocusable?.focusableId
                        );
                    }
                }, 300);
            }
        }
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    }

    render() {
        const { carouselActive, showCatalog } = this.state;
        const { rowsOrder, rowGroups, carouselContent } = this.props;

        return (
            <>
                { showCatalog ?
                    <Navigation ref={el => (this.navigation = el)}>
                        <div ref={el => (this.catalog = el)} className="catalog">
                            <HorizontalList>
                                <Sidebar
                                    ref={el => (this.sidebar = el)}
                                    history={this.props.history}
                                    navigation={this.navigation}
                                    parent={this}
                                />
                                <VerticalList ref={el => (this.mainFocus = el)} retainLastFocus>
                                    { carouselContent?.length > 0 && 
                                        <Focusable
                                            className="carousel--focusable"
                                            ref={el => (this.carouselFocusable = el)}
                                            onMouseEnter={this.carouselMouseEnter}
                                            onFocus={() => {
                                                this.setCarouselActive(true);
                                                this.setListActive();
                                            }}
                                            onBlur={() => this.setCarouselActive(false)}
                                            navDefault={true}
                                        >
                                            <Carousel
                                                parent={this}
                                                infiniteLoop={true}
                                                showThumbs={false}
                                                useKeyboardArrows={true}
                                                autoPlay={true}
                                                showStatus={false}
                                                ref={el => (this.carousel = el)}
                                                images={this.props.carouselContent}
                                                focusableItem={this.carouselFocusable}
                                                className={
                                                    'carousel' +
                                                    (carouselActive ? ' carousel--active' : '')
                                                }
                                                redirectTo={this.redirectTo}
                                                goBack={() => goBack(this.props.history)}
                                            />
                                        </Focusable>
                                    }
                                    { rowsOrder
                                        .filter( groupName => rowGroups[groupName]?.length > 0 )
                                        .map( (groupName, i ) => (
                                            <RowGroup
                                                key={i}
                                                groupIndex={i}
                                                rows={rowGroups[groupName]}
                                                groupName={groupName}
                                                catalog={this}
                                                navigation={this.navigation}
                                                viewport={this.catalog}
                                                ref={el => (this.rowsRef[i] = el)}
                                                setListActive={this.setListActive}
                                            />
                                        ))
                                    }
                                </VerticalList>
                            </HorizontalList>
                        </div>
                    </Navigation>
                    :
                    <div className="catalog">
                       <Loader className="catalog__loader" />
                    </div>
                }
            </>
        );
    }
}

Catalog.contextType = AuthContext;

const stateToProps = state => ({
    carouselContent: state.catalog.carousel,
    dataLoaded: state.catalog.dataLoaded,
    lastFocus: state.catalog.lastFocus,
    rowGroups: state.catalog.rowGroups,
    rowsOrder: state.catalog.rowsOrder,
    themeActive: state.catalog.themeActive,
    themes: state.catalog.themes
});

const dispatchToProps = dispatch => ({
    getFullCatalog: (themeId, userId) => dispatch(getFullCatalogNew(themeId, userId))
});

export default connect(stateToProps, dispatchToProps)(Catalog);
