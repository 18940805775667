import React from 'react';
import PropTypes from 'prop-types';

const baseClass = 'overlay';

const getClassName = (className, blur) => {
    let classes = baseClass + (className ? ` ${className}` : '');
    classes += blur ? ` ${baseClass}--blur` : '';
    return classes;
};

const Overlay = ({className, blur, children}) => (
    <div className={getClassName(className, blur)}>{children}</div>
);

Overlay.propTypes = {
    className: PropTypes.string,
    overlay: PropTypes.bool,
    blur: PropTypes.bool
};
Overlay.defaultProps = {
    blur: false
};
export default Overlay;
