import ApiService from 'services/ApiService';

const getThemeId = theme => {
    switch (theme) {
        case 'chasse':
            return 2;
        case 'peche':
            return 3;
        default:
            return 0;
    }
};

const catalogOrder = [
    'spotlights',
    'news',
    'pinnedFirst',
    'inProgress',
    'mostViewed',
    'favorites',
    'pinnedSecond',
    'moments',
    'forUser',
    'pages',
    'completed'
];

const getCarouselRequest = themeId => {
    return ApiService.get(`/movies/getSliderMovies.php?themeId=${themeId}`)
        .then(res => {
            let carousel = [];
            if (res.data.ret) {
                carousel = carousel.concat(res.data.data[0].movies);
            }
            return carousel;
        })
        .catch(() => []);
};

const getRowsRequestsNew = (themeId, userId) => {
    let favorites, progresses, forUser;
    if (userId) {
        favorites = ApiService.get(
            `/movies/getFavoritesMovies.php?themeId=${themeId}&NoCli=${userId}`
        )
            .then(res => {
                if (!res.data.ret) throw new Error();
                return { favorites: res.data.data };
            })
            .catch(() => ({
                favorites: []
            }));

        //Progresses (inProgress, completed)
        progresses = ApiService.get(
            `/movies/getProgressMovies.php?themeId=${themeId}&NoCli=${userId}`
        )
            .then(res => {
                if (!res.data.ret) throw new Error();
                res.data.data.inProgress.forEach(data => (data.display.withProgression = true));
                return res.data.data;
            })
            .catch(() => ({
                inProgress: [],
                completed: []
            }));

        forUser = ApiService.get(`/movies/getForUserMovies.php?themeId=${themeId}&NoCli=${userId}`)
            .then(res => {
                if (!res.data.ret) throw new Error();
                return { forUser: res.data.data };
            })
            .catch(() => ({
                forUser: []
            }));
    }

    const moments = ApiService.get(`/movies/getMomentMovies.php?themeId=${themeId}&NoCli=${userId}`)
        .then(res => {
            if (!res.data.ret) throw new Error();
            return { moments: res.data.data };
        })
        .catch(() => ({
            moments: []
        }));

    const mostViewed = ApiService.get(
        `/movies/getMostViewedMovies.php?themeId=${themeId}&NoCli=${userId}`
    )
        .then(res => {
            if (!res.data.ret) throw new Error();
            return { mostViewed: res.data.data };
        })
        .catch(() => ({
            mostViewed: []
        }));

    const news = ApiService.get(`/movies/getNewMovies.php?themeId=${themeId}&NoCli=${userId}`)
        .then(res => {
            if (!res.data.ret) throw new Error();
            return { news: res.data.data };
        })
        .catch(() => ({
            news: []
        }));

    const pages = ApiService.get(`/movies/getPageMovies.php?themeId=${themeId}&NoCli=${userId}`)
        .then(res => {
            if (!res.data.ret) throw new Error();
            return { pages: res.data.data };
        })
        .catch(() => ({
            pages: []
        }));

    //Pins (pinnedFirst, PinnedSecond)
    const pins = ApiService.get(`/movies/getPinnedMovies.php?themeId=${themeId}&NoCli=${userId}`)
        .then(res => {
            if (!res.data.ret) throw new Error();
            return res.data.data;
        })
        .catch(() => ({
            pinnedFirst: [],
            pinnedSecond: []
        }));

    const spotlights = ApiService.get(
        `/movies/getSpotlightedMovies.php?themeId=${themeId}&NoCli=${userId}`
    )
        .then(res => {
            if (!res.data.ret) throw new Error();
            return { spotlights: res.data.data };
        })
        .catch(() => ({
            spotlights: []
        }));

    return Promise.all([
        spotlights,
        news,
        pins,
        progresses,
        mostViewed,
        favorites,
        moments,
        forUser,
        pages
    ])
        .then(values => {
            let catalog = {};
            catalogOrder.forEach(catalogRow => {
                const valueIndex = values.findIndex(row => Array.isArray(row[catalogRow]));
                catalog[catalogRow] = values[valueIndex][catalogRow];
            });

            return catalog;
        })
        .catch(() => []);
};

export const getFullCatalogNew = (theme, userId) => async dispatch => {
    const themeId = getThemeId(theme);
    dispatch({
        type: 'GET_FULL_CATALOG_NEW',
        payload: {
            carousel: await getCarouselRequest(themeId),
            rows: await getRowsRequestsNew(themeId, userId)
        }
    });
};

export const getCarousel = theme => async dispatch => {
    const themeId = getThemeId(theme);
    dispatch({
        type: 'GET_CAROUSEL',
        payload: await getCarouselRequest(themeId)
    });
};

export const getCatalogRows = (theme, userId) => async dispatch => {
    const themeId = getThemeId(theme);
    dispatch({
        type: 'GET_CATALOG',
        payload: await getRowsRequestsNew(themeId, userId)
    });
};

export const setMovie = (movie, nextPlaylist) => async dispatch => {
    dispatch({
        type: 'SET_MOVIE',
        payload: { movie, nextPlaylist }
    });
};

export const getMovie = (movieId, userId) => async dispatch => {
    const res = await ApiService.get(`/movies/getMovieById.php?movieId=${movieId}&NoCli=${userId}`);
    const movie = res.data.data[0];
    dispatch({
        type: 'GET_MOVIE',
        payload: { movie }
    });
};

export const setThemeActive = theme => async dispatch => {
    dispatch({
        type: 'SET_THEME',
        payload: theme
    });
};

const toggleUserFavoriteMovie = async (isInFavorite, movieId, userId) => {
    const action = isInFavorite ? 'remove' : 'add';
    return ApiService.get(
        `user/toggleFavorites.php?action=${action}&NoCli=${userId}&movieId=${movieId}`
    )
        .then(response => {
            if (!response.data.ret) throw new Error();
            return true;
        })
        .catch(() => false);
};

export const addMovieToFavorites = (isInFavorite, movie, userId) => async dispatch => {
    const res = await toggleUserFavoriteMovie(isInFavorite, movie.transaction, userId);
    if (!res) return;

    if (!isInFavorite) {
        dispatch({
            type: 'ADD_MOVIE_TO_FAVORITES',
            payload: movie
        });
    } else {
        dispatch({
            type: 'REMOVE_MOVIE_FROM_FAVORITES',
            payload: movie
        });
    }
};

const updateProgressMovieRequest = (userId, movieId, currentTime, totalTime, device) => {
    const params = new URLSearchParams();
    params.append('NoCli', userId);
    params.append('movieId', movieId);
    params.append('progressTime', currentTime);
    params.append('timeTotal', totalTime);
    params.append('device', device);
    return ApiService.post('movies/updateProgressMovies.php', params)
        .then(response => {
            if (!response.data.ret) throw new Error();
            return true;
        })
        .catch(err => false);
};

export const updateProgressMovie =
    (userId, movie, currentTime, totalTime, device) => async dispatch => {
        const res = await updateProgressMovieRequest(
            userId,
            movie.transaction,
            currentTime,
            totalTime,
            device
        );
        if (!res) return;

        dispatch({
            type: 'UPDATE_STORE_PROGRESS_MOVIE',
            payload: {
                movie,
                currentTime,
                totalTime
            }
        });
    };

export const setCatalogLastFocus = position => async dispatch => {
    dispatch({
        type: 'SET_LAST_FOCUS',
        payload: {
            position
        }
    });
};

const getSearchRequest = (search, userId) => {
    return ApiService.get(`/movies/getSearchedMoviesByString.php?searchText=${search}&NoCli=${userId}`)
        .then(res => {
            if (!res.data.ret) throw new Error();
            return res.data.data[0].movies;
        })
        .catch(() => []);
};

export const getSearch = (search, userId) => async dispatch => {
    const res = await getSearchRequest(search, userId);

    dispatch({
        type: 'SEARCH_MOVIE',
        payload: {
            searchText: search,
            searchResult: res
        }
    });
};

// Requests not used yet
export const getCategories = params => ({
    type: 'GET_CATEGORIES',
    payload: ApiService.get(`/movies/getCatsList.php${params}`)
});
