const initialState = {
    categories: [],
    themes: ['monz3', 'chasse', 'peche', 'search'],
    // TODO: search should not be a theme. Will be delete with layout (used for selected item in sidebar menu)
    themeActive: 0,
    dataLoaded: false,
    movie: null,
    nextPlaylist: [],
    carousel: [],
    rowGroups: {},
    rowsOrder: [
        'spotlights',
        'news',
        'pinnedFirst',
        'inProgress',
        'mostViewed',
        'favorites',
        'pinnedSecond',
        'moments',
        'forUser',
        'pages',
        'completed'
    ],
    lastFocus: [],
    searchText: null,
    searchResult: []
};

const CatalogReducer = (state = initialState, action) => {
    switch (action.type) {
        case 'SET_MOVIE':
        case 'GET_MOVIE': {
            let { movie, nextPlaylist } = action.payload;

            if (!nextPlaylist || nextPlaylist.length <= 0) {
                nextPlaylist = state.nextPlaylist;
            }
            // (TEMPORARY) Construct seasons
            if (movie.type === 'series') {
                const episodes = [...movie.episodes];
                movie.seasons = [
                    {
                        number: 1,
                        episodes: episodes
                    }
                    // {
                    //     number: 2,
                    //     episodes: movie.episodes.map((episode, i) => ({
                    //         ...episode,
                    //         titre: `Episode ${i + 1} saison  2`,
                    //         transaction: episode.transaction + 'a'
                    //     }))
                    // }
                ];

                movie.episodeToStartIndexes = {
                    seasonIndex: 0,
                    episodeIndex: 0
                };

                for (let si = 0; si < movie.seasons?.length; si++) {
                    const season = movie.seasons[si];
                    const isLastSeason = si >= movie.seasons.length - 1;

                    for (let ei = 0; ei < season.episodes.length; ei++) {
                        const { time, timeTotal } = season.episodes[ei];
                        const isLastEpisode = ei >= season.episodes.length - 1;

                        if (time && timeTotal) {
                            if (timeTotal - time > 60) {
                                movie.episodeToStartIndexes = {
                                    seasonIndex: si,
                                    episodeIndex: ei
                                };
                            } else {
                                // Considered as video ended
                                if (isLastSeason && isLastEpisode) {
                                    movie.episodeToStartIndexes = {
                                        seasonIndex: 0,
                                        episodeIndex: 0
                                    };
                                } else {
                                    movie.episodeToStartIndexes = {
                                        seasonIndex: isLastEpisode ? si + 1 : si,
                                        episodeIndex: ei + 1
                                    };
                                }
                            }
                        }
                    }
                }
            }
            return {
                ...state,
                movie,
                nextPlaylist
            };
        }

        case 'GET_FULL_CATALOG': {
            const { carousel, rows } = action.payload;
            return {
                ...state,
                carousel,
                rows,
                dataLoaded: true
            };
        }

        case 'GET_FULL_CATALOG_NEW': {
            const { carousel, rows: rowGroups } = action.payload;
            return {
                ...state,
                carousel,
                rowGroups,
                dataLoaded: true
            };
        }

        case 'SET_THEME': {
            let themeChanged = false;
            const themeActive = state.themes.indexOf(action.payload);
            if (state.themeActive !== themeActive) {
                themeChanged = true;
            }
            return {
                ...state,
                themeActive,
                dataLoaded: !themeChanged
            };
        }

        case 'ADD_MOVIE_TO_FAVORITES': {
            const movie = action.payload;
            const rowGroups = { ...state.rowGroups };
            rowGroups.favorites[0].movies.push(movie);
            return {
                ...state,
                rowGroups
            };
        }

        case 'REMOVE_MOVIE_FROM_FAVORITES': {
            const movie = action.payload;
            const rowGroups = { ...state.rowGroups };
            rowGroups.favorites[0].movies = rowGroups.favorites[0].movies.filter(m => {
                return m.transaction !== movie.transaction;
            });
            return {
                ...state,
                rowGroups
            };
        }

        case 'UPDATE_STORE_PROGRESS_MOVIE': {
            let { movie, currentTime, totalTime } = action.payload;

            if (!state.rowGroups?.inProgress) return { ...state };

            const rowGroups = { ...state.rowGroups };
            currentTime = Math.floor(currentTime).toString();
            totalTime = Math.floor(totalTime).toString();

            const progressRow = rowGroups.inProgress[0];
            let movieFound = false;

            for (let i = 0; i < progressRow.movies.length; i++) {
                if (progressRow.movies[i].transaction === movie.transaction) {
                    movieFound = true;
                    progressRow.movies[i].progressTime = currentTime;
                    progressRow.movies[i].timeTotal = totalTime;
                    break;
                }
            }

            if (!movieFound) {
                progressRow.movies.unshift({
                    ...movie,
                    progressTime: currentTime,
                    timeTotal: totalTime
                });
            }
            rowGroups.inProgress[0] = progressRow;

            return {
                ...state,
                rowGroups
            };
        }

        case 'SET_LAST_FOCUS': {
            const { position: lastFocus } = action.payload;

            return {
                ...state,
                lastFocus
            };
        }

        case 'SEARCH_MOVIE': {
            const { searchText, searchResult } = action.payload;

            return {
                ...state,
                searchText,
                searchResult
            };
        }
        default:
            return { ...state };
    }
};

export default CatalogReducer;
