import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

const baseClass = 'icon';
const getClasses = (className, separator, selected) => {
    let classes = (className ? `${className} ` : '') + baseClass;
    classes += separator ? ` ${baseClass}--separator` : '';
    classes += selected ? ` ${baseClass}--selected` : '';
    return classes;
};

const SvgIcon = ({ icon: Icon, className, separator, selected, ...restProps }) => {
    const classes = useMemo(() => {
        return getClasses(className, separator, selected);
    }, [className, separator, selected]);

    return <Icon className={classes} {...restProps} />;
};

SvgIcon.propTypes = {
    icon: PropTypes.object.isRequired,
    className: PropTypes.string,
    height: PropTypes.string,
    separator: PropTypes.bool, // TODO
    selected: PropTypes.bool, // TODO
    width: PropTypes.string
};

SvgIcon.defaultProps = {
    className: ''
};

export default SvgIcon;
