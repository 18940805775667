import React, { createContext, useEffect, useReducer } from 'react';
import ApiService from 'services/ApiService';

const initialState = {
    isAuthenticated: false,
    isInitialized: false,
    user: null
};

const reducer = (state, action) => {
    switch (action.type) {
        case 'INIT': {
            const { isAuthenticated, user } = action.payload;
            return {
                ...state,
                isAuthenticated,
                isInitialized: true,
                user
            };
        }
        case 'LOGIN': {
            const { user } = action.payload;
            return {
                ...state,
                isAuthenticated: true,
                user
            };
        }
        case 'LOGOUT': {
            return {
                ...state,
                isAuthenticated: false,
                user: null
            };
        }
        default: {
            return { ...state };
        }
    }
};

const ITEM_NO_CLIENT = 'NO_CLIENT';

const setSession = user => {
    localStorage.setItem(ITEM_NO_CLIENT, user.NoCli);
    localStorage.setItem('LOGIN_TOKEN', JSON.stringify(user));
};

const clearSession = () => {
    localStorage.removeItem(ITEM_NO_CLIENT);
    localStorage.removeItem('LOGIN_TOKEN');
};

const AuthContext = createContext({
    ...initialState,
    login: () => Promise.resolve(),
    logout: () => {}
});

const AuthProvider = ({ children }) => {
    const [state, dispatch] = useReducer(reducer, initialState);

    useEffect( () => {
        ( async () => {
            try {
                const user = JSON.parse(localStorage.getItem('LOGIN_TOKEN'));
                let isAuthenticated = false;
                if (user) {
                    // Platform is not accessible if you're not subscribed to the service, so authenticated = subscribed
                    isAuthenticated = await checkSubscription(user.NoCli);
                    if ( ! isAuthenticated ) logout();
                }
                dispatch({
                    type: 'INIT',
                    payload: { isAuthenticated, user }
                });
            } catch (err) {
                dispatch({
                    type: 'INIT',
                    payload: {
                        isAuthenticated: false,
                        user: null
                    }
                });
            }
        })();
    }, []);

    const login = async (email, password) => {
        const params = new URLSearchParams();
        params.append('email', email);
        params.append('password', password);

        let result = { sub: false };
        const res = await ApiService.post('user/login.php', params);

        if ( res && res.data ) {
            if ( res.data.ret ) {
                const { user } = res.data;
                setSession(user);
                dispatch({
                    type: 'LOGIN',
                    payload: { user }
                });

                result.sub = true;
            } else if ( res.data.data ) {
                result.error = res.data.data;
            }
        }

        return result;
    };

    const logout = () => {
        clearSession();
        dispatch({ type: 'LOGOUT' });
    };

    const checkSubscription = async noCli => {
        return ApiService.get(`user/getUserInfo.php?NoCli=${noCli}`)
            .then(res => {
                return res.data && res.data.ret && res.data.data && res.data.data.status && ! res.data.data.status.discarded;
            })
            .catch(() => {
                return false;
            });
    };

    return (
        <AuthContext.Provider
            value={{
                ...state,
                login,
                logout
            }}
        >
            {children}
        </AuthContext.Provider>
    );
};

export { AuthContext as default, AuthProvider };
