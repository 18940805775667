import React, { Component } from 'react';
import { connect } from 'react-redux';
import Navigation, { VerticalList, HorizontalList, Grid } from 'components/Navigation';
import { withLastLocation } from 'react-router-last-location';

import { AuthContext } from 'contexts';
import Sidebar from 'components/SideBar';
import Wrapper from 'components/Wrapper';
import Input from 'components/Input';
import ListItem from 'components/ListItem';
import Text, { ALIGN_CENTER } from 'components/Text';
import { getSearch } from 'redux/actions/CatalogActions';
import { goBack, goTo, isReturnEvent, PATH_SEARCH } from 'utils/NavigationUtils';

/**
 * Component corresponding to the search page
 */
class Search extends Component {
    /**
     * Numbers of columns for the search result display
     * @type {number}
     */
    static GRID_COLUMNS = 4;

    /**
     * Number of chars minimum to start search
     */
    static SEARCH_MIN_LEN = 3;

    /**
     * Contains search results
     * @type {[]}
     */
    images = [];

    /**
     * Contains the reference of the result search scrollable div
     */
    scrollableDiv;

    /**
     * Constructor of the Search class
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            isSearchStarted: false,
            inputActive: false,
            mouseFocus: false,
            actualIndex: null,
            isLoaded: false,
            pageLoaded: true,
            search: '',
            activeItem: null,
            rerender: true,
        };

        this.navigation = React.createRef();
        this.scrollableDiv = React.createRef();

        this.keyDownListener = this.keyDownListener.bind(this);
        this.rerenderTimeout = null;
    }

    /**
     * Request search on API
     * @param value
     * @returns {Promise<void>}
     */
    async search(value) {
        this.props.getSearch(value, this.context.user.NoCli);

        // return await axios
        //     .get(getSearch(value))
        //     .then(resp => {
        //         const mesImages = [];
        //         if (!resp.data.data.is_empty) {
        //             Object.values(resp.data.data).forEach(item => {
        //                 if (item) {
        //                     mesImages.push({
        //                         uri: getImageUrl(item.transaction, typeThumb),
        //                         aff_titre: item.aff_titre,
        //                         titre: item.titre,
        //                         transaction: item.transaction,
        //                         item: item
        //                     });
        //                 }
        //             });
        //         }
        //         return mesImages;
        //     })
        //     .catch(err => {
        //         return false;
        //     });
    }

    /**
     * Check if request has to be done
     * @param text
     * @returns {[]}
     */
    updateSearch(text) {
        this.setState({ isLoaded: false });

        if (text && text.length >= Search.SEARCH_MIN_LEN) {
            //Min 3 characters
            this.setState({
                // isSearchStarted: true,
                activeItem: null
            });

            this.search(text.trim());
            // .then(images => {
            //     this.props.searchResult = images;
            //     this.setState({ isSearchStarted: false });
            // })
            // .catch(err => {
            //     this.setState({ isSearchStarted: false });
            // });
        } else {
            // this.props.searchResult = [];
        }
        this.setState({ isLoaded: true, rerender: ! this.state.rerender });
        this.rerenderTimeout = setTimeout( () => { this.setState({ rerender: true }); }, 500);
    }

    /**
     * Change actual item index focus
     * @param index
     */
    setActualIndex(index) {
        this.setState({ actualIndex: index });
    }

    /**
     * Change state inputActive value
     * @param active
     */
    setInputActive(active) {
        this.setState({ inputActive: active });
    }

    /**
     * Set mouseFocus state to given state
     * @param active
     */
    setMouseFocus(active) {
        this.setState({ mouseFocus: active });
    }

    /**
     * Set activeItem state to given index or null
     * @param index
     */
    setItemActive(index = null) {
        this.setState({ activeItem: index });
    }

    /**
     * Opdn sidebar on specified item focus
     * @param evnt
     */
    keyDownListener(evnt) {
        if (isReturnEvent(evnt) && this.sidebar && this.navigation && !this.sidebar.state.active) {
            this.sidebar.setActive(true);
            this.navigation.forceFocus(this.sidebar.menu.quit.item.focusableId);
            goTo(this.props.history, PATH_SEARCH, true);
        }
    }

    /**
     * Restore last search from session if comeback from detail page & set state loaded to true
     */
    componentDidMount() {
        // if (this.props.lastLocation && this.props.lastLocation.pathname.includes('detail')) {
        //     this.setState({  });
        // }
        this.setState({
            isLoaded: true,
            pageLoaded: true,
            search: this.props.searchText ? this.props.searchText : ''
        });
        window.addEventListener('keydown', this.keyDownListener);
    }

    shouldComponentUpdate(nextProps, nextState, nextContext) {
        if (this.props.match.params.action === 'quit' && !nextProps.match.params.action) {
            return false;
        }
        return true;
    }

    componentDidUpdate(prevProps, prevState, snapshot) {
        if (prevState.search !== this.state.search) {
            this.updateSearch(this.state.search);
        }

        // if ( this.props.match.params.action === 'quit' ) {
        //   this.keyDownListener( new KeyboardEvent( 'keydown', { keyCode: 27 } ) );
        // }
    }

    /**
     * Remove eventlistener
     */
    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
        clearTimeout( this.rerenderTimeout );
    }

    /**
     * Render method of the Search class
     * @returns {JSX.Element}
     */
    render() {
        return (
            <Navigation
                ref={el => {
                    this.navigation = el;
                }}
            >
                <div ref={el => (this.searchPage = el)} className="search">
                    {this.state.pageLoaded && (
                        <HorizontalList>
                            <Sidebar
                                ref={el => (this.sidebar = el)}
                                history={this.props.history}
                                navigation={this.navigation}
                                parent={this}
                            />
                            <VerticalList>
                                <Wrapper>
                                    <Input
                                        type="text"
                                        label="search"
                                        name="search"
                                        navigation={this.navigation}
                                        onChange={text =>
                                            this.setState({ search: text.target.value })
                                        }
                                        focusOnLoad={true}
                                        autoFocus={true}
                                        withLabel={false}
                                        value={this.state.search}
                                        parent={this}
                                        autoComplete="off"
                                    />
                                </Wrapper>
                                {/* <div
                                    className={
                                        'search__content__overlay' +
                                        (!this.state.inputActive ? '--hide' : '')
                                    }
                                /> */}
                                <div className="search__content" ref={this.scrollableDiv}>
                                    {this.state.search?.length < 3 && (
                                        <Text align={ALIGN_CENTER}>
                                            Tape un/des mots clefs correspondant aux film que tu
                                            recherches.
                                            <br />
                                            Tu peux rechercher directement le titre d'un film, un
                                            lieu, un type de gibier/poisson, une technique, un
                                            personnage...
                                        </Text>
                                    )}
                                    {this.state.search?.length >= 3 &&
                                        (this.props.searchResult?.length <= 0 ? (
                                            <Text align={ALIGN_CENTER}>Aucun résultat</Text>
                                        ) : (
                                            <>
                                            {
                                                this.state.rerender &&
                                                <Grid
                                                    ref={el => (this.grid = el)}
                                                    columns={Search.GRID_COLUMNS}
                                                    rows={Math.ceil(
                                                        this.props.searchResult?.length /
                                                            Search.GRID_COLUMNS
                                                    )}
                                                >
                                                    {this.props.searchResult?.map((item, i) => {
                                                        return (
                                                            <ListItem
                                                                categoryIndex={`0-${i}`}
                                                                goBack={() =>
                                                                    goBack(this.props.history)
                                                                }
                                                                item={item}
                                                                itemIndex={i}
                                                                key={i}
                                                                lazyLoadingShift={20}
                                                                navigation={this.navigation}
                                                                parentCategory={this}
                                                                parentList={this}
                                                                redirectTo={url =>
                                                                    goTo(this.props.history, url)
                                                                }
                                                                setThisItemActive={() =>
                                                                    this.setItemActive(i)
                                                                }
                                                                verticalAutoScroll
                                                                vGap={40}
                                                                viewport={this.scrollableDiv.current}
                                                            />
                                                        );
                                                    })}
                                                </Grid>
                                            }
                                            </>
                                           
                                        ))}
                                </div>
                            </VerticalList>
                        </HorizontalList>
                    )}
                </div>
            </Navigation>
        );
    }
}

Search.contextType = AuthContext;

const stateToProps = state => ({
    searchText: state.catalog.searchText,
    searchResult: state.catalog.searchResult
});

const dispatchToProps = dispatch => ({
    getSearch: (search, userId) => dispatch(getSearch(search, userId))
});

// export default withLastLocation(Search);
export default connect(stateToProps, dispatchToProps)(withLastLocation(Search));
