import React, {
    Component /*, useState, createRef, useMemo, useCallback, forwardRef */
} from 'react';
import { connect /*, useDispatch, useSelector*/ } from 'react-redux';
import { Focusable } from 'components/Navigation';
import PropTypes from 'prop-types';

import Text from './Text';
import CssClassUtils from 'utils/CssClassUtils';
import {
    getImageUrl,
    typeThumb,
    typePoster,
    typeSquare,
    visual1,
    visual5,
    visual8,
    visual10,
} from 'api/ImageManager';
import { setMovie, setCatalogLastFocus } from 'redux/actions/CatalogActions';

const baseClass = 'list-item';
const getClasses = (active, type, size) => {
    let classes = baseClass;
    classes += active ? ` ${baseClass}--active` : '';
    classes += type ? ` ${baseClass}--${type.toLowerCase()}` : ` ${baseClass}--thumbnail`;
    classes += size ? ` ${baseClass}--${size.toLowerCase()}` : '';
    return classes;
};

class ListItem extends Component {
    static CSS_CLASS_BASE = 'list-item';

    static CSS_CLASS_ACTIVE =
        CssClassUtils.CLASS_SEPARATOR +
        ListItem.CSS_CLASS_BASE +
        CssClassUtils.CLASS_MOD_SEPARATOR +
        CssClassUtils.CLASS_STATE_ACTIVE;

    poster;

    constructor(props) {
        super(props);
        this.state = {
            isLoaded: false,
            active: false,
            imageStatus: false,
            titleVisible: true
        };

        this.itemImg = React.createRef();

        this.onFocus = this.onFocus.bind(this);
        this.onBlur = this.onBlur.bind(this);
        this.onEnterDown = this.onEnterDown.bind(this);
        this.isInViewport = this.isInViewport.bind(this);
        this.onMouseEnter = this.onMouseEnter.bind(this);
    }

    /**
     * Horizontal or/and vertical scroll if the focus item not in viewport
     */
    isInViewport(horizontal, horizontalGap = 0, vertical = false, verticalGap = 0) {
        const image = this.itemImg;
        if (!image) return;
        if (horizontal) {
            //Horizontal scroll
            const list = image.parentNode.parentNode;

            const itemLeft = image.getBoundingClientRect().left - image.offsetWidth * 0.05;
            const itemRight = image.getBoundingClientRect().right + image.offsetWidth * 0.05;
            const { left, right } = list.getBoundingClientRect();

            if (itemRight > right) {
                list.scrollLeft += itemRight - right + horizontalGap;
            } else if (itemLeft < left) {
                list.scrollLeft += itemLeft - left /*+ horizontalGap*/;
            }
        }

        if (vertical) {
            //Vertical scroll
            const { viewport } = this.props;

            const itemTop = image.getBoundingClientRect().top - image.offsetHeight * 0.05;
            const itemBottom = image.getBoundingClientRect().bottom + image.offsetHeight * 0.05;
            const { top, bottom } = viewport.getBoundingClientRect();

            if (itemBottom > bottom) {
                viewport.scrollTop += itemBottom - bottom + verticalGap;
            } else if (itemTop < top) {
                viewport.scrollTop += itemTop - top /*- verticalGap*/;
            }
        }
    }

    /**
     * Returns if true the component is in the screen or close to be
     * @returns {boolean}
     */
    isItemVisible() {
        const { parentList, itemIndex } = this.props;
        const { type: itemType, size: itemSize } = this.props.style;

        let lazyLoadingShift = 5;
        if (this.props.lazyLoadingShift) {
            lazyLoadingShift = this.props.lazyLoadingShift;
        } else if (itemType === 'square') {
            lazyLoadingShift = 6;
        } else if (itemType === 'poster') {
            if (itemSize === 'XS') lazyLoadingShift = 9;
            else if (itemSize === 'XL') lazyLoadingShift = 5;
            else lazyLoadingShift = 7;
        } else if (itemType === 'posterLong') {
            if (itemSize === 'XXS') lazyLoadingShift = 10;
            if (itemSize === 'XS') lazyLoadingShift = 9;
            else if (itemSize === 'XL') lazyLoadingShift = 5;
            else lazyLoadingShift = 7;
        }

        return (
            itemIndex > parentList.state.activeItem - lazyLoadingShift &&
            itemIndex < parentList.state.activeItem + lazyLoadingShift
        );
    }

    /**
     * @TODO
     */
    handleImageLoaded() {
        if (this.props.item.aff_titre !== '1') {
            this.setState({ titleVisible: false });
        }
        this.setState({ imageStatus: true });
    }

    /**
     * @TODO
     */
    handleImageErrored() {
        this.setState({ imageStatus: false });
    }

    /**
     * Change active state to false on blur event
     */
    onBlur() {
        this.setState({ active: false });
    }

    /**
     * Change some states chen item focus
     * @param index
     * @param evt
     * @returns {boolean}
     */
    onFocus(index, evt) {
        if (!this.state.isLoaded) {
            return false;
        }
        this.props.parentCategory.setActualIndex(index);
        this.props.parentCategory.setMouseFocus(false);

        this.setState({ active: true });
        this.props.setThisItemActive();
        this.isInViewport(
            this.props.horizontalAutoScroll,
            this.props.hGap,
            this.props.verticalAutoScroll,
            this.props.vGap
        );
    }

    /**
     * Focus item and set state mouseFocus to true
     */
    onMouseEnter() {
        if (this.props.navigation) {
            this.props.navigation.forceFocus(this.itemFocusable.focusableId);
        }
        this.props.parentCategory.setMouseFocus(true);
    }

    /**
     * Redirect to detail page or player for resume reading row
     */
    onEnterDown() {
        if (!this.state.active) return;
        const { item, withProgression, setMovie, setCatalogLastFocus, redirectTo } =
            this.props;
        setMovie(item);
        if (this.props.retainFocus) {
            setCatalogLastFocus(this.itemFocusable?.focusableId);
        }
        redirectTo((withProgression ? '/play/' : '/detail/') + item.transaction);
    }

    componentDidMount() {
        const { item } = this.props;

        switch (this.props.style.type) {
            case 'poster':
                this.poster = getImageUrl(item.transaction, typePoster, visual5);
                break;
            case 'posterLong':
                this.poster = getImageUrl(item.transaction, typePoster, visual10);
                break;
            case 'square':
                this.poster = getImageUrl(item.transaction, typeSquare, visual8);
                break;
            case 'thumbnail':
            default:
                this.poster = getImageUrl(item.transaction, typeThumb, visual1);
        }
        this.setState({ isLoaded: true });
    }

    /**
     * Prevent rendering component if not in screen
     * @param nextProps
     * @param nextState
     * @param nextContext
     * @returns {boolean}
     */
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.isItemVisible();
    }

    render() {
        const { item, withProgression, style } = this.props;
        const progress = Math.floor((+item.progressTime * 100) / +item.timeTotal);
        return (
            <Focusable
                className={getClasses(this.state.active, style.type, style.size)}
                ref={el => (this.itemFocusable = el)}
                onMouseEnter={this.onMouseEnter}
                onFocus={(i, evt) => this.onFocus(i, evt)}
                onBlur={this.onBlur}
                onClick={this.onEnterDown}
                onEnterDown={this.onEnterDown}
            >
                {this.state.isLoaded && (
                    <>
                        <img
                            ref={el => (this.itemImg = el)}
                            src={this.poster}
                            alt={this.props.item.titre}
                            onLoad={this.handleImageLoaded.bind(this)}
                            onError={this.handleImageErrored.bind(this)}
                        />
                        {this.state.titleVisible && (
                            <Text shadow={true}>{this.props.item.titre}</Text>
                        )}
                        {withProgression && (
                            <div className={ListItem.CSS_CLASS_BASE + '__progress-bar'}>
                                <div
                                    className={
                                        ListItem.CSS_CLASS_BASE + '__progress-bar--progression'
                                    }
                                    style={{ width: `${progress}%` }}
                                />
                            </div>
                        )}
                    </>
                )}
            </Focusable>
        );
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
    setCatalogLastFocus: position => dispatch(setCatalogLastFocus(position)),
    setMovie: movie => dispatch(setMovie(movie))
});

export default connect(stateToProps, dispatchToProps, null, { forwardRef: true })(ListItem);

ListItem.propTypes = {
    categoryIndex: PropTypes.string,
    goBack: PropTypes.func,
    hGap: PropTypes.number,
    horizontalAutoScroll: PropTypes.bool,
    item: PropTypes.object,
    itemIndex: PropTypes.number,
    navigation: PropTypes.object,
    parentList: PropTypes.object,
    parentCategory: PropTypes.object,
    redirectTo: PropTypes.func,
    retainFocus: PropTypes.bool,
    setThisItemActive: PropTypes.func,
    style: PropTypes.object,
    verticalAutoScroll: PropTypes.bool,
    vGap: PropTypes.number,
    viewport: PropTypes.object,
    withProgression: PropTypes.bool
};

ListItem.defaultProps = {
    horizontalAutoScroll: false,
    hGap: 0,
    retainFocus: false,
    style: { type: '', size: '' },
    verticalAutoScroll: false,
    vGap: 0,
    withProgression: false
};

// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// ------------------------------------------------------------------------------------------------
// const LAZY_LOADING_SHIFT = 5;

// const baseClass = 'list-item';
// const getClasses = (active, type, size) => {
//     let classes = baseClass;
//     classes += active ? ` ${baseClass}--active` : '';
//     classes += type ? ` ${baseClass}--${type}` : '';
//     classes += size ? ` ${baseClass}--${size}` : '';
//     return classes;
// };

// const ListItemV2 = (
//     {
//         hGap,
//         horizontalAutoScroll,
//         item,
//         itemIndex,
//         navigation,
//         parentCategory,
//         parentList,
//         setThisItemActive,
//         style,
//         verticalAutoScroll,
//         vGap,
//         viewport,
//         withProgression,
//         redirectTo
//     },
//     ref
// ) => {
//     const dispatch = useDispatch();

//     const [isActive, setIsActive] = useState(false);
//     const [isLoaded, setIsLoaded] = useState(false);
//     // const [imageStatus, setImageStatus] = useState(true);
//     const [titleVisible, setTitleVisible] = useState(true);

//     let focusableRef = createRef();
//     let imageRef = createRef();

//     const imageSrc = useMemo(() => {
//         let url = 'https://manager.zone300.com/api/cimage/webroot/img.php?src=';
//         switch (style.type) {
//             case 'square': {
//                 url += `${item.transaction}-VISUEL8.jpg&width=500&height=500`;
//                 break;
//             }
//             case 'poster': {
//                 url += `${item.posterFileName}.jpg&height=600`;
//                 break;
//             }
//             case 'thumbnail':
//             default: {
//                 url += `${item.thumbnailFileName}.jpg&h=200&crop-to-fit`;
//             }
//         }
//         return url;
//     }, [item, style]);

//     const currentProgression = useMemo(() => {
//         Math.floor((+item.progressTime * 100) / +item.timeTotal);
//     }, [item]);

//     const isItemVisible = useCallback(() => {
//         const { activeItem } = parentList.state;
//         return (
//             itemIndex > activeItem - LAZY_LOADING_SHIFT &&
//             itemIndex < activeItem + LAZY_LOADING_SHIFT
//         );
//     }, [itemIndex, parentList.state]);

//     const isInViewport = useCallback(() => {
//         if (!imageRef) return;

//         if (horizontalAutoScroll) {
//             //Horizontal scroll
//             const list = imageRef.parentNode.parentNode;

//             const itemLeft = imageRef.getBoundingClientRect().left - imageRef.offsetWidth * 0.05;
//             const itemRight = imageRef.getBoundingClientRect().right + imageRef.offsetWidth * 0.05;
//             const { left, right } = list.getBoundingClientRect();

//             if (itemRight > right) {
//                 list.scrollLeft += itemRight - right + hGap;
//             } else if (itemLeft < left) {
//                 list.scrollLeft += itemLeft - left /*+ hGap*/;
//             }
//         }

//         if (verticalAutoScroll) {
//             //Vertical scroll
//             const { viewport } = this.props;

//             const itemTop = imageRef.getBoundingClientRect().top - imageRef.offsetHeight * 0.05;
//             const itemBottom =
//                 imageRef.getBoundingClientRect().bottom + imageRef.offsetHeight * 0.05;
//             const { top, bottom } = viewport.getBoundingClientRect();

//             if (itemBottom > bottom) {
//                 viewport.scrollTop += itemBottom - bottom + vGap;
//             } else if (itemTop < top) {
//                 viewport.scrollTop += itemTop - top /*- vGap*/;
//             }
//         }
//     }, [hGap, horizontalAutoScroll, imageRef, vGap, verticalAutoScroll]);

//     const itemAction = () => {
//         if (!isActive) return;
//         const { item, withProgression } = this.props;
//         dispatch(setMovie(item));
//         redirectTo((withProgression ? '/play/' : '/detail/') + item.transaction);
//     };

//     const onMouseEnterHandler = () => {
//         if (navigation) navigation.forceFocus(focusableRef.focusableId);
//         parentCategory.setMouseFocus(true);
//     };

//     const onFocusHandler = (i, event) => {
//         if (!isLoaded) return false;

//         parentCategory.setActualIndex(i);
//         parentCategory.setMouseFocus(false);
//         setIsActive(true);
//         setThisItemActive();
//         isInViewport();
//     };

//     const onBlurHandler = () => setIsLoaded(false);

//     const onClickHandler = () => itemAction();

//     const onEnterDownHandler = () => itemAction();

//     const onImageLoadedHandler = () => {
//         if (item.aff_titre !== '1') setTitleVisible(false);
//         // setImageStatus(true);
//     };

//     const onImageErrorHandler = () => {
//         //setImageStatus(false);
//     };

//     // useEffect(() => {}, [])

//     return (
//         <Focusable
//             className={getClasses(isActive, style.type, style.size)}
//             ref={el => (focusableRef = el)}
//             onMouseEnter={onMouseEnterHandler}
//             onFocus={onFocusHandler}
//             onBlur={onBlurHandler}
//             onClick={onClickHandler}
//             onEnterDown={onEnterDownHandler}
//         >
//             <>
//                 <img
//                     ref={el => (imageRef = el)}
//                     src={imageSrc}
//                     alt={item.titre}
//                     onLoad={onImageLoadedHandler}
//                     onError={onImageErrorHandler}
//                 />
//                 {titleVisible && <Text shadow={true}>{item.titre}</Text>}
//                 {withProgression && (
//                     <div className={`${baseClass}__progress-bar`}>
//                         <div
//                             className={`${baseClass}__progress-bar--progression`}
//                             style={{ width: `${currentProgression}%` }}
//                         />
//                     </div>
//                 )}
//             </>
//         </Focusable>
//     );
// };

// export default forwardRef(ListItemV2);
