import React, { useEffect } from 'react';
import { useHistory } from 'react-router-dom';
import Image from 'components/Image';
import Wrapper from 'components/Wrapper';
import Title, { LEVEL_1, LEVEL_2 } from 'components/Title';
import Text, { SIZE_LARGE } from 'components/Text';
import StringUtils from 'utils/StringUtils';
import { goBack, isReturnEvent } from 'utils/NavigationUtils';

const Subscription = ({ hostname }) => {
    const history = useHistory();

    useEffect(() => {
        const keyDownListener = event => { if ( isReturnEvent( event ) ) goBack(history); };
        window.addEventListener('keydown', keyDownListener);
        return () => window.removeEventListener('keydown', keyDownListener);
    }, [ history ]);

    return (
        <>
            <div className="inscription">
                <Image url={require('../assets/img/grille-hd.jpg')} size={Image.SIZE_FULLSCREEN} />
                <Wrapper overlay={Wrapper.OVERLAY_GRADIENT_BACK}>
                    <Title level={LEVEL_1}>
                        <Image alt="Zone 300" url={require('../assets/img/logo_big.png')} />
                    </Title>
                    <Title level={LEVEL_2}>
                        {StringUtils.toCapitalize("pour continuer l'inscription rendez-vous sur")}
                    </Title>
                    <Text size={SIZE_LARGE}>{'https://www.zone300.com/' + hostname}</Text>
                    <Image
                        url={require('../assets/img/qrcode-' + hostname + '.png')}
                        alt="qr code"
                    />
                </Wrapper>
            </div>
        </>
    );
};
export default Subscription;
