import React, { useState, useMemo, createRef, useEffect } from 'react';
import { HorizontalList } from 'components/Navigation';
import PropTypes from 'prop-types';

import Title from 'components/Title';
import Episodes from './Episodes';
import Seasons from './Seasons';

const baseClass = 'details__series';

const getCssClasses = isVisible => {
    let classes = baseClass;
    classes += isVisible ? ` ${baseClass}--visible` : '';
    return classes;
};

let mouseFocusCoolDown = false;
let timeout;

const DetailsSeries = ({ isVisible, setVisible, title, seasons, navigation }) => {
    const [mouseFocus, setMouseFocus] = useState(false);
    const [episodeToFocus, setEpisodeToFocus] = useState(null);
    const [episodeToScrollTo, setEpisodeToScrollTo] = useState(null);

    const [itemFocusedIndex, setItemFocusedIndex] = useState({
        season: null,
        episode: null
    });
    const [seasonActiveIndex, setSeasonActiveIndex] = useState(0);
    let hListRef = createRef();

    // List of all seasons episode concatenated
    const episodes = useMemo(() => {
        const list = [];

        seasons.forEach(season => {
            list.push(
                ...season.episodes.map(episode => ({
                    ...episode,
                    season: season.number
                }))
            );
        });
        return list;
    }, [seasons]);

    const setEpisodeAction = (action, episode) => {
        switch (action) {
            case 'scroll':
                setEpisodeToScrollTo(episode);
                break;
            case 'focus':
            default:
                setEpisodeToFocus(episode);
        }
    };

    const manageFocus = (type, index) => {
        if (!isVisible) setVisible(true);

        switch (type) {
            case 'season':
                setItemFocusedIndex({
                    episode: null,
                    season: index
                });
                break;
            case 'episode':
                const episode = episodes[index];
                if (episode.season !== seasons[seasonActiveIndex].number) {
                    const seasonIndex = seasons.findIndex(
                        season => season.number === episode.season
                    );

                    hListRef.children[0].lastFocusChild = seasonIndex;
                    setSeasonActiveIndex(seasonIndex);
                }
                setItemFocusedIndex({
                    episode: index,
                    season: null
                });
                break;
            default:
                break;
        }
    };

    const setMouseFocusHandler = value => {
        if (mouseFocusCoolDown) return;

        if (value) {
            mouseFocusCoolDown = true;
            setTimeout(() => {
                mouseFocusCoolDown = false;
            }, 500);
        }
        setMouseFocus(value);
    };

    useEffect(() => {
        return () => clearTimeout(timeout);
    }, []);

    return (
        <div className={getCssClasses(isVisible)}>
            <Title className="details__series__title">{title}</Title>
            <HorizontalList ref={r => (hListRef = r)} className="details__series__hz">
                {seasons?.length > 0 && (
                    <>
                        <div className="details__series__seasons">
                            {seasons.length > 0 && (
                                <Seasons
                                    manageFocus={i => manageFocus('season', i)}
                                    navigation={navigation}
                                    seasons={seasons}
                                    setEpisodeAction={setEpisodeAction}
                                    setMouseFocus={setMouseFocusHandler}
                                    seasonFocusedIndex={itemFocusedIndex.season}
                                    seasonActiveIndex={seasonActiveIndex}
                                    setSeasonActiveIndex={setSeasonActiveIndex}
                                />
                            )}
                        </div>
                        <div className="details__series__episodes">
                            {episodes.length > 0 && (
                                <Episodes
                                    navigation={navigation}
                                    episodes={episodes}
                                    episodeFocusedIndex={itemFocusedIndex.episode}
                                    manageFocus={i => manageFocus('episode', i)}
                                    mouseFocus={mouseFocus}
                                    setMouseFocus={setMouseFocusHandler}
                                    episodeToFocus={episodeToFocus}
                                    setEpisodeToFocus={setEpisodeToFocus}
                                    episodeToScrollTo={episodeToScrollTo}
                                    setEpisodeToScrollTo={setEpisodeToScrollTo}
                                />
                            )}
                        </div>
                    </>
                )}
            </HorizontalList>
        </div>
    );
};

DetailsSeries.propTypes = {
    isVisible: PropTypes.bool,
    navigation: PropTypes.object,
    seasons: PropTypes.arrayOf(PropTypes.object),
    setVisible: PropTypes.func,
    title: PropTypes.string
};

DetailsSeries.defaultProps = {};

export default DetailsSeries;
