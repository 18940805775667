import React, { Component } from 'react';
import Menu from './Menu';
import CssClassUtils from 'utils/CssClassUtils';

/**
 * Sidebar component
 */
class Sidebar extends Component {
    /**
     * The base CSS class for the sidebar
     * @type {string}
     */
    static CSS_CLASS_BASE = 'sidebar';

    /**
     * The not active state
     */
    static STATE_HIDE = 'hide';

    /**
     * The css class for the active state
     */
    static CSS_CLASSS_STATE_HIDE =
        CssClassUtils.CLASS_SEPARATOR +
        Sidebar.CSS_CLASS_BASE +
        CssClassUtils.CLASS_MOD_SEPARATOR +
        Sidebar.STATE_HIDE;

    /**
     * The css class for the focus state
     */
    static CSS_CLASSS_STATE_FOCUS =
        CssClassUtils.CLASS_SEPARATOR +
        Sidebar.CSS_CLASS_BASE +
        CssClassUtils.CLASS_MOD_SEPARATOR +
        CssClassUtils.CLASS_STATE_FOCUS;

    /**
     * Constructor of the Sidebar classe
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            focus: false
        };

        this.onSidebarClick = this.onSidebarClick.bind(this);
    }

    /**
     * Change the active state of the object depending on the status arg
     * @param status
     */
    setActive(status) {
        this.setState({ active: status });
    }

    /**
     * Return classes for the Sidebar component
     * @param active
     * @param focus
     */
    getClasses(active, focus) {
        let classes = Sidebar.CSS_CLASS_BASE;

        classes += !active ? Sidebar.CSS_CLASSS_STATE_HIDE : '';

        classes += focus ? Sidebar.CSS_CLASSS_STATE_FOCUS : '';

        return classes;
    }

    /**
     * Focus the first element of the sidebar and change the active state
     */
    onSidebarClick() {
        if (!this.state.active) {
            this.props.navigation.forceFocus(this.menu.menuVList.focusableId);
        } else {
            this.setActive(false);
        }
    }

    render() {
        const { active, focus } = this.state;
        return (
            <>
                <div
                    className={this.getClasses(active, focus)}
                    onClick={this.onSidebarClick}
                    onMouseEnter={() => this.setState({ focus: true })}
                    onMouseOut={() => this.setState({ focus: false })}
                >
                    <Menu
                        ref={el => (this.menu = el)}
                        navigation={this.props.navigation}
                        page={this.props.parent}
                        history={this.props.history}
                        visible={this.state.active}
                        openSidebar={() => this.setActive(true)}
                        closeSidebar={() => this.setActive(false)}
                    />

                    <div className="sidebar__logo">
                        <img src={require('../assets/img/logo-24.png')} alt="Logo Zone300" />
                    </div>
                </div>
                {/* {this.state.active && (
                    <div className={'sidebar__overlay'} onClick={() => this.setActive(false)} />
                )} */}
            </>
        );
    }
}

export default Sidebar;
