import { applyMiddleware, combineReducers, createStore } from 'redux';
import ReduxThunk from 'redux-thunk';
import { createLogger } from 'redux-logger';
import reducers from './reducers';

const states = {};

let middleware;
if (process.env.NODE_ENV === 'production') {
	middleware = applyMiddleware(ReduxThunk);
} else {
	middleware = applyMiddleware(ReduxThunk, createLogger({ duration: true }));
}
const appReducer = combineReducers(reducers);

const rootReducer = (state, action) => {
	let newState = state;
	if (action.type === 'DESTROY_SESSION') newState = undefined;
	return appReducer(newState, action);
};

const store = createStore(rootReducer, states, middleware);

export default store;
