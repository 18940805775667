import React from 'react';
import PropTypes from 'prop-types';

import CssClassUtils from 'utils/CssClassUtils';
import Text, { SIZE_LARGE } from './Text';

/**
 * This components is the label for inputs.
 *
 *
 **/
class Label extends React.Component {
    /**
     * The default value.
     **/
    static VALUE_DEFAULT = 'Remplissez ce champs';

    /**
     * The focused state.
     */
    static STATE_FOCUS = 'focus';

    /**
     * The base css class name.
     **/
    static CSS_CLASS_BASE = 'form__label';

    /**
     * The focus css class name.
     **/
    static CLASS_STATE_FOCUS =
        Label.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Label.STATE_FOCUS;

    /**
     * Return the css classes depending on the params).
     *
     * @param bool isActive If the label is active or no.
     * @return string The css classes.
     *
     *
     **/
    getCssClasses(isActive) {
        let classes = Label.CSS_CLASS_BASE;

        if (isActive) {
            classes += CssClassUtils.CLASS_SEPARATOR + Label.CLASS_STATE_FOCUS;
        }

        return classes;
    }

    /**
     * Render the Label.
     *
     *
     */
    render() {
        return (
            <label
                className={this.getCssClasses(this.props.isActive)}
                htmlFor={'input-' + this.props.name}
            >
                <Text size={SIZE_LARGE}>{this.props.value}</Text>
            </label>
        );
    }
}

/**
 * @props string name The name attribute of the label.
 * @props string value The value.
 * @props bool isActive If the corresponding input is active.
 **/
Label.propTypes = {
    name: PropTypes.string,
    value: PropTypes.string,
    isActive: PropTypes.bool
};
/**
 *
 * @type {{isActive: boolean, value: string}}
 */
Label.defaultProps = {
    value: Label.VALUE_DEFAULT,
    isActive: false
};

export default Label;
