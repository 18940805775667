import React, { Component } from 'react';
import PropTypes from 'prop-types';
import Title, { LEVEL_2, COLOR_PRIMARY } from './Title';
import List from './List';
import Arrow from './Arrow';

import { Focusable } from './Navigation';
import Carousel from './Carousel';

/**
 * Category contains the category title and a list of items
 */
class Row extends Component {
    /**
     * Default name for the category if none given
     * @type {string}
     */
    static NAME_DEFAULT = 'sans nom';

    /**
     * Display arrow when element focus is greater than the value
     * @type {number}
     */
    static LEFT_ARROW_MIN_INDEX = 0;

    /**
     * Number of list to display before and after the active one
     */
    static SHIFT_DISPLAY_LIST = 4;

    /**
     * Item index focus
     */
    actualIndex;

    /**
     * Constructor for the Category class
     * @param props
     */
    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            mouseFocus: false,
            activeList: null,
            carouselActive: false,
        };

        this.actualIndex = 0;
        this.row = React.createRef();
        this.list = React.createRef();

        this.carouselMouseEnter = this.carouselMouseEnter.bind(this);
    }

    /**
     * Set focus on carousel on mouse enter event
     */
    carouselMouseEnter() {
        this.setCarouselActive( true );
        this.props.navigation.forceFocus(this.carouselFocusable.focusableId);
    }

    /**
     * Set carouselActive state and focus/blur actual visible carousel element
     * @param active
     */
    setCarouselActive(active) {
        this.setState({ carouselActive: active });

        if (this.carousel && this.carousel.carousel) {
            active
                ? this.carousel.carousel.carouselWrapperRef.focus()
                : this.carousel.carousel.carouselWrapperRef.blur();
        }
    }

    /**
     * Focus next/previous element in the list depending on the direction arg
     * @param direction
     */
    focusNewElement(direction) {
        const newIndex = this.actualIndex + (direction ? 1 : -1);
        if (newIndex >= 0 && newIndex < this.props.items.length) {
            this.props.navigation.forceFocus(
                this.list.itemsRef[newIndex].itemFocusable.focusableId
            );
        }
        this.setMouseFocus(true);
    }

    /**
     * Change actual item index focus
     * @param index
     */
    setActualIndex(index) {
        this.actualIndex = index;
    }

    /**
     * Set mouseFocus state to given state
     * @param active
     */
    setMouseFocus(active) {
        this.setState({ mouseFocus: active });
    }

    /**
     * Returns if true the component is in the screen or close to be
     * @returns {boolean}
     */
    isCategoryVisible() {
        const { parent, categoryIndex } = this.props;

        if (!parent.state.activeList) return true;

        const toNumbers = arr => arr.map(Number);
        const calcPos = pos => (pos[0] > 0 ? pos[0] + 1 : 0) + pos[1];

        const activePos = toNumbers(parent.state.activeList.split('-'));
        const activeCount = calcPos(activePos);

        const categoryPos = toNumbers(categoryIndex.split('-'));
        const categoryCount = calcPos(categoryPos);

        return (
            categoryCount > activeCount - Row.SHIFT_DISPLAY_LIST &&
            categoryCount < activeCount + Row.SHIFT_DISPLAY_LIST
        );
    }

    /**
     * Set state loaded to true after component mount
     */
    componentDidMount() {
        this.setState({ isLoaded: true });
    }

    /**
     * Prevent rendering component if not in screen
     * @param nextProps
     * @param nextState
     * @param nextContext
     * @returns {boolean}
     */
    shouldComponentUpdate(nextProps, nextState, nextContext) {
        return this.isCategoryVisible();
    }

    /**
     * Render method for the Category class
     * @returns {JSX.Element}
     */
    render() {
        const {
            displayProps,
            parent,
            categoryIndex,
            items,
            setListActive,
            redirectTo,
            goBack,
            navigation,
            viewport
        } = this.props;

        if ( displayProps.style === 'slider' ) {
            return (
                <div ref={el => (this.row = el)} className="category">
                    <Title level={LEVEL_2} color={COLOR_PRIMARY}>
                        {displayProps.name}
                    </Title>
                    <Focusable
                        className="carousel--focusable"
                        ref={el => (this.carouselFocusable = el)}
                        onMouseEnter={this.carouselMouseEnter}
                        onFocus={() => {
                            this.setCarouselActive(true);
                            setListActive(categoryIndex);
                        }}
                        onBlur={() => this.setCarouselActive(false)}
                    >
                        <Carousel
                            parent={this}
                            infiniteLoop={true}
                            showThumbs={false}
                            useKeyboardArrows={true}
                            autoPlay={true}
                            showStatus={false}
                            ref={el => (this.carousel = el)}
                            images={items}
                            focusableItem={this.carouselFocusable}
                            className={
                                'carousel' +
                                (this.state.carouselActive ? ' carousel--active' : '')
                            }
                            redirectTo={redirectTo}
                            goBack={goBack}
                        />
                    </Focusable>
                </div>
            )
        }

        return (
            <div ref={el => (this.row = el)} className="category">
                <Title level={LEVEL_2} color={COLOR_PRIMARY}>
                    {displayProps.name}
                </Title>
                <div className="category__arrow__container">
                    {parent.state.activeList === categoryIndex &&
                        this.state.mouseFocus &&
                        this.actualIndex > Row.LEFT_ARROW_MIN_INDEX && (
                            <Arrow
                                direction={Arrow.DIRECTION_LEFT}
                                background={Arrow.BCKGND_GRADIENT}
                                onClick={() => this.focusNewElement(false)}
                            />
                        )}
                    {parent.state.activeList === categoryIndex &&
                        this.state.mouseFocus &&
                        this.actualIndex < items.length - 1 && (
                            <Arrow
                                direction={Arrow.DIRECTION_RIGHT}
                                background={Arrow.BCKGND_GRADIENT}
                                onClick={() => this.focusNewElement(true)}
                            />
                        )}
                    <List
                        ref={el => (this.list = el)}
                        items={items}
                        rowRef={this.row}
                        categoryIndex={categoryIndex}
                        setThisListActive={() => setListActive(categoryIndex)}
                        parentCategory={this}
                        redirectTo={redirectTo}
                        goBack={goBack}
                        navigation={navigation}
                        style={{
                            type: displayProps.styleNew ? displayProps.styleNew : displayProps.style,
                            size: displayProps.sizeNew ? displayProps.sizeNew : displayProps.size,
                        }}
                        viewport={viewport}
                        wrappedList={false}
                        withProgression={displayProps.withProgression}
                    />
                </div>
            </div>
        );
    }
}

export default Row;

Row.propTypes = {
    displayProps: PropTypes.object,
    items: PropTypes.array,
    parent: PropTypes.object,
    redirectTo: PropTypes.func,
    goBack: PropTypes.func,
    navigation: PropTypes.object,
    viewport: PropTypes.object
};

/**
 *
 * @type {{name: string}}
 */
Row.defaultProps = {
    name: Row.NAME_DEFAULT
};
