import React, { Component } from 'react';
import Navigation, { HorizontalList } from 'components/Navigation';
import { withLastLocation } from 'react-router-last-location';
import axios from 'axios';

// import CarouselFullScreen from 'components/CarouselFullScreen';
import Wrapper from 'components/Wrapper';
import Button, {
    SIZE_GIANT as BUTTON_GIANT,
    BLOCK_FULL as BUTTON_FULL,
    BLOCK_HALF as BUTTON_HALF
} from 'components/Button';
import { goTo, isReturnEvent, PATH_EXIT, PATH_LOGIN } from 'utils/NavigationUtils';
import Text, { SIZE_LARGE as TEXT_LARGE } from 'components/Text';
import Image from 'components/Image';
import { apiLandingCarouselImage } from 'api/ApiManager';
import { getImageUrl, typeCarouselFullscreen } from 'api/ImageManager';

/**
 * This components is the landing page.
 **/
class Landing extends Component {
    /**
     * Subdomains name inplements in app indication for subscription
     */
    static DOMAINS_WITH_SUBSCRIPTION = ['vewd', 'zeasn', 'swisscom'];

    /**
     * The navigation object.
     */
    navigation = null;

    constructor(props) {
        super(props);

        this.state = {
            isLoaded: false,
            subscribeBtn: Landing.DOMAINS_WITH_SUBSCRIPTION.includes(this.props.hostname),
            image: null
        };
        this.keyDownListener = this.keyDownListener.bind(this);
    }

    /**
     * Open sidebar on specified item focus
     * @param evnt
     */
    keyDownListener(evnt) {
        if (isReturnEvent(evnt)) {
            goTo(this.props.history, PATH_EXIT);
        }
    }

    getBackground() {
        axios.get(apiLandingCarouselImage()).then(res => {
            this.setState({
                image: getImageUrl(res.data[0], typeCarouselFullscreen),
                isLoaded: true
            });
        });
    }

    componentDidMount() {
        // this.setState({ isLoaded: true });
        this.getBackground();
        window.addEventListener('keydown', this.keyDownListener);
    }

    /**
     * Remove eventlistener
     */
    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    }

    /**
     * Render the landing page.
     *
     *
     */
    render() {
        return (
            <Navigation ref={el => (this.navigation = el)}>
                <HorizontalList className="landing">
                    {/* <CarouselFullScreen /> */}
                    <img
                        alt="Présentation de Zone 300"
                        className="landing__background"
                        size={Image.SIZE_FULLSCREEN}
                        src={this.state.image}
                    />
                    <Wrapper sticky={Wrapper.STICKY_BOTTOM} padding={Wrapper.PADDING_NONE}>
                        <Button
                            navigation={this.state.isLoaded ? this.navigation : null}
                            block={this.state.subscribeBtn ? BUTTON_HALF : BUTTON_FULL}
                            size={BUTTON_GIANT}
                            url={PATH_LOGIN}
                            history={this.props.history}
                        >
                            <Text size={TEXT_LARGE}>Connexion</Text>
                        </Button>
                        {this.state.subscribeBtn && (
                            <Button
                                navigation={this.state.isLoaded ? this.navigation : null}
                                block={BUTTON_HALF}
                                size={BUTTON_GIANT}
                                url="/inscription/"
                                history={this.props.history}
                            >
                                <Text size={TEXT_LARGE}>Inscription</Text>
                            </Button>
                        )}
                    </Wrapper>
                </HorizontalList>
            </Navigation>
        );
    }
}

export default withLastLocation(Landing);
