import React, { Component } from 'react';
import PropTypes from 'prop-types';
import SvgIcon from './SvgIcon';
import CssClassUtils from 'utils/CssClassUtils';

import { ReactComponent as ChevronUp } from 'assets/icons/icon-chevron-up.svg';
import { ReactComponent as ChevronRight } from 'assets/icons/icon-chevron-right.svg';
import { ReactComponent as ChevronDown } from 'assets/icons/icon-chevron-down.svg';
import { ReactComponent as ChevronLeft } from 'assets/icons/icon-chevron-left.svg';

/**
 * Arrow component, can be clickable, can contains overlay
 */
class Arrow extends Component {
    /**
     * The base css class name.
     * @type {string}
     **/
    static CSS_CLASS_BASE = 'arrow';

    /**
     * The gradient background
     * @type {string}
     */
    static BCKGND_GRADIENT = 'gradient';

    /**
     * The up direction
     * @type {string}
     */
    static DIRECTION_UP = 'up';

    /**
     * The right direction
     * @type {string}
     */
    static DIRECTION_RIGHT = 'right';

    /**
     * The down direction
     * @type {string}
     */
    static DIRECTION_DOWN = 'down';

    /**
     * The down direction
     * @type {string}
     */
    static DIRECTION_LEFT = 'left';

    /**
     * The gradient background css class name
     * @type {string}
     */
    static CSS_CLASS_BCKGND_GRADIENT =
        Arrow.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Arrow.BCKGND_GRADIENT;

    /**
     * The up direction css class name
     * @type {string}
     */
    static CSS_CLASS_DIRECTION_UP =
        Arrow.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Arrow.DIRECTION_UP;

    /**
     * The right direction css class name
     * @type {string}
     */
    static CSS_CLASS_DIRECTION_RIGHT =
        Arrow.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Arrow.DIRECTION_RIGHT;

    /**
     * The down direction css class name
     * @type {string}
     */
    static CSS_CLASS_DIRECTION_DOWN =
        Arrow.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Arrow.DIRECTION_DOWN;

    /**
     * The left direction css class name
     * @type {string}
     */
    static CSS_CLASS_DIRECTION_LEFT =
        Arrow.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Arrow.DIRECTION_LEFT;

    /**
     * Returns the classes for the arrow component
     * @param direction
     * @param background
     * @returns {string}
     */
    getClasses(direction, background) {
        let classes = Arrow.CSS_CLASS_BASE;

        switch (direction) {
            case Arrow.DIRECTION_UP:
                classes += CssClassUtils.CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_UP;
                break;
            case Arrow.DIRECTION_RIGHT:
                classes += CssClassUtils.CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_RIGHT;
                break;
            case Arrow.DIRECTION_DOWN:
                classes += CssClassUtils.CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_DOWN;
                break;
            case Arrow.DIRECTION_LEFT:
                classes += CssClassUtils.CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_LEFT;
                break;
            default:
                classes += CssClassUtils.CLASS_SEPARATOR + Arrow.CSS_CLASS_DIRECTION_RIGHT;
        }

        switch (background) {
            case 'gradient':
                classes += CssClassUtils.CLASS_SEPARATOR + Arrow.CSS_CLASS_BCKGND_GRADIENT;
                break;
            default:
                break;
            // classes += CssClassUtils.CLASS_SEPARATOR + Arrow.CSS_CLASS_BCKGND_GRADIENT;
        }

        return classes;
    }

    getIcon(direction) {
        switch (direction) {
            case Arrow.DIRECTION_UP:
                return ChevronUp;

            case Arrow.DIRECTION_DOWN:
                return ChevronDown;
            case Arrow.DIRECTION_LEFT:
                return ChevronLeft;
            case Arrow.DIRECTION_RIGHT:
            default:
                return ChevronRight;
        }
    }

    /**
     * Render method of the Arrow class
     * @returns {JSX.Element}
     */
    render() {
        const { direction, background } = this.props;
        return (
            <div className={this.getClasses(direction, background)} onClick={this.props.onClick}>
                <SvgIcon icon={this.getIcon(direction)} height="2vw" width="2vw" />
            </div>
        );
    }
}

export default Arrow;

Arrow.propTypes = {
    direction: PropTypes.oneOf([
        Arrow.DIRECTION_UP,
        Arrow.DIRECTION_RIGHT,
        Arrow.DIRECTION_DOWN,
        Arrow.DIRECTION_LEFT
    ]),
    background: PropTypes.oneOf([Arrow.BCKGND_GRADIENT]),
    onClick: PropTypes.func
};
