import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Focusable } from 'components/Navigation';
import CssClassUtils from 'utils/CssClassUtils';

import Label from 'components/Label';

class Input extends Component {
    /**
     * The base css class name.
     **/
    static CSS_CLASS_BASE = 'form__input';

    /**
     * The medium size.
     **/
    static SIZE_MEDIUM = 'size-medium';

    /**
     * The big size.
     **/
    static SIZE_BIG = 'big';

    /**
     * The focused state.
     */
    static STATE_FOCUS = 'focus';

    /**
     * The focus css class name.
     **/
    static CLASS_STATE_FOCUS =
        Input.CSS_CLASS_BASE + CssClassUtils.CLASS_MOD_SEPARATOR + Input.STATE_FOCUS;

    /**
     * The medium css class name.
     **/
    static CSS_CLASS_SIZE_MEDIUM =
        Input.CSS_CLASS_BASE + CssClassUtils.CLASS_BLOCK_SEPARATOR + Input.SIZE_MEDIUM;

    /**
     * The transparent style class name.
     */
    static CSS_CLASS_SIZE_BIG =
        Input.CSS_CLASS_BASE + CssClassUtils.CLASS_BLOCK_SEPARATOR + Input.SIZE_BIG;

    /**
     * Constructor of the Input class
     * Set the active state to false.
     *
     * @param props
     *
     */
    constructor(props) {
        super(props);

        this.state = {
            active: false,
            inputActive: false
        };
        this.inputFocusable = React.createRef();
    }

    /**
     * Return the css classes depending on the params.
     *
     * @return string The css classes.
     *
     *
     **/
    getCssClasses(size, isActive) {
        let classes = Input.CSS_CLASS_BASE;

        if (size === Input.SIZE_BIG) {
            classes += CssClassUtils.CLASS_SEPARATOR + Input.CSS_CLASS_SIZE_BIG;
        } else {
            classes += CssClassUtils.CLASS_SEPARATOR + Input.CSS_CLASS_SIZE_MEDIUM;
        }

        if (isActive) {
            classes += CssClassUtils.CLASS_SEPARATOR + Input.CLASS_STATE_FOCUS;
        }

        return classes;
    }

    /**
     * Set the active state to false when blur
     *
     *
     */
    onBlur() {
        this.setState({ active: false });
        this.input.blur();
    }

    /**
     * Set the active state to true when focus
     *
     *
     */
    onFocus() {
        this.setState({ active: true });
        // if ( this.props.parent )this.props.parent.setInputActive( true );
    }

    onFocusInput() {
        if (this.props.navigation)
            this.props.navigation.forceFocus(this.inputFocusable.focusableId);
    }

    /**
     * Set the input active and force the focus.
     *
     *
     */
    onClick() {
        this.props.navigation.forceFocus(this.inputFocusable.current.focusableId);
    }

    /**
     * Render the html input.
     *
     *
     */
    render() {
        return (
            <Focusable
                ref={this.inputFocusable}
                onFocus={() => this.onFocus()}
                onBlur={() => this.onBlur()}
                onEnterDown={(e, n) => {
                    this.state.inputActive ? this.input.blur() : this.input.focus();
                }}
                navDefault={this.props.autoFocus}
            >
                <div className="form__group">
                    {this.props.withLabel && (
                        <Label
                            name={this.props.name}
                            value={this.props.label}
                            isActive={this.state.active}
                        ></Label>
                    )}
                    <input
                        ref={input => {
                            this.input = input;
                        }}
                        id={'input-' + this.props.name}
                        className={this.getCssClasses(this.props.size, this.state.active)}
                        autoComplete={this.props.autoComplete}
                        autoFocus={this.props.autoFocus}
                        name={this.props.name}
                        tabIndex={this.props.tabIndex}
                        type={this.props.type}
                        value={this.props.value}
                        onBlur={() => {
                            this.setState({ inputActive: false });
                            if (this.props.parent) this.props.parent.setInputActive(false);
                        }}
                        onFocus={() => {
                            this.setState({ inputActive: true, active: true });
                            if (this.props.navigation && this.inputFocusable.current)
                                this.props.navigation.forceFocus(
                                    this.inputFocusable.current.focusableId
                                );
                        }}
                        onChange={this.props.onChange}
                        onClick={() => this.onClick()}
                    />
                </div>
            </Focusable>
        );
    }
}

/**
 * @props string type The type of the input.
 * @props string label The label value of the input.

 * @props Object navigation The navigation object to permit the forceFocus().

 * @props string name The name of the input.
 * @props string size The size of the input. Giant, big or medium, medium by default.
 **/
Input.propTypes = {
    type: PropTypes.string,
    label: PropTypes.string,
    focusOnLoad: PropTypes.bool,
    name: PropTypes.string,
    size: PropTypes.oneOf([Input.SIZE_MEDIUM, Input.SIZE_BIG]),
    withLabel: PropTypes.bool,
    value: PropTypes.string,
    autoComplete: PropTypes.string,
    autoFocus: PropTypes.bool,
    tabIndex: PropTypes.number
};

/**
 * @type {{autoComplete: boolean, focusOnLoad: boolean, style: (string|*), block: (string|*), withLabel: boolean, value: (string|*)}}
 */
Input.defaultProps = {
    focusOnLoad: false,
    value: Input.VALUE_DEFAULT,
    style: Input.STYLE_TRANSPARENT,
    block: Input.BLOCK_DEFAULT,
    withLabel: true,
    autoComplete: 'false',
    autoFocus: false,
    tabIndex: null
};

export default Input;
