/**
 * Version target on server side
 * @type { string }
 */
const vServeur = '.4.2';

/**
 * Domain name for vod-dev
 * @type {string}
 */
const apiDomain = 'https://vod-dev.zone300.com/';

/**
 * Prefix for API calls on vod-dev
 * @type {string}
 */
const apiRoute = 'api/';

/**
 * Complete API URL for vod-dev
 * @type {string}
 */
const apiUrl = apiDomain + apiRoute;

/**
 * Domain name for admin-vod
 * @type {string}
 */
const apiAdminDomain = 'https://admin-vod.zone300.com/';

/**
 * Prefix for API calls on admin-vod
 * @type {string}
 */
const apiAdminRoute = 'api/';

const apiAdminURL = apiAdminDomain + apiAdminRoute;
const API_IMAGE = `${apiAdminURL}cimage/webroot/img.php?src=`;

/**
 * Returns the complete API URL for admin-vod
 * @type {string}
 */
const getApiAdminUrl = apiAdminDomain + apiAdminRoute;

/**
 * Returns the URL to retrieve the user customization data
 * @param NoCli
 * @returns {string}
 * @constructor
 */
const getUserCustomization = NoCli => {
    return apiUrl + 'appli' + vServeur + '/get_Personnalisation_v2.php?NoCli=' + NoCli;
};

/**
 * Returns the URL to retrieve the catalog of a category
 * @param category
 * @returns {string}
 * @constructor
 */
const getCategoryCatalog = category => {
    return apiUrl + 'getAccueil_appli.php?action=' + category;
};

/**
 * Returns the URL to retrieve the carousel content of a category
 * @param category
 * @returns {string}
 * @constructor
 */
const getCategoryCarousel = category => {
    return apiUrl + 'appli' + vServeur + '/getCarousel.php?Categ=' + category;
};

/**
 * Returns the URL to retrieve the data of a film
 * @param filmId
 * @returns {string}
 * @constructor
 */
// const getMovie = filmId => {
//     return apiUrl + 'appli' + vServeur + '/getFilm.php?transaction=' + filmId;
// };

/**
 * Returns the URL to retrieve the result of a search
 * @param search
 * @returns {string}
 * @constructor
 */
const getSearch = search => {
    return apiUrl + 'getSearch_v3b.php?action=search&q=' + search;
};

/**
 * URL for landing page carousel's data
 * @type {string}
 */
const apiLandingCarouselImage = () => {
    return getApiAdminUrl + 'appli/getAccueil.php';
};

export {
    apiAdminDomain as default,
    apiAdminURL,
    API_IMAGE,
    getApiAdminUrl,
    getUserCustomization,
    getCategoryCatalog,
    getCategoryCarousel,
    // getMovie,
    getSearch,
    apiLandingCarouselImage
};
