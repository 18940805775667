import { Component } from 'react';

/**
 * Utils containing CSS classes variables used by components
 */
class CssClassUtils extends Component {
    /**
     * The css classes separator.
     */
    static CLASS_SEPARATOR = ' ';

    /**
     * The css class state separator.
     */
    static CLASS_MOD_SEPARATOR = '--';

    /**
     * The css class block separator.
     */
    static CLASS_BLOCK_SEPARATOR = '__';

    /**
     * The CSS class active state
     */
    static CLASS_STATE_ACTIVE = 'active';

    /**
     * The CSS class active state
     */
    static CLASS_STATE_FOCUS = 'focus';
}

export default CssClassUtils;
