import React, { Component } from 'react';
import { HorizontalList } from 'components/Navigation';
import PropTypes from 'prop-types';

import ListItem from './ListItem.js';

/**
 * Horizontal list, wrappable, containing ListItems
 */
class List extends Component {
    /**
     * Array containing references of the items
     * @type {[]}
     */
    itemsRef = [];

    /**
     * Gap value in px for the vertical scroll on list focus
     * @type {number}
     */
    static SCROLL_GAP = 40;

    /**
     * Constructor of the List class
     * @param props
     */
    constructor(props) {
        super(props);
        this.leftArrowDisabled = false;
        this.rightArrowDisabled = false;
        this.hzScrollActive = true;

        this.state = {
            isLoaded: false,
            activeItem: null
        };

        this.horizontalList = React.createRef();
        this.onFocusList = this.onFocusList.bind(this);
    }

    /**
     * Set activeItem state to given index or null
     * @param index
     */
    setItemActive(index = null) {
        this.setState({ activeItem: index });
    }

    /**
     * Scroll to see the list in screen if not totally visible on focus event,
     * set active state to true
     */
    onFocusList() {
        const { viewport, rowRef } = this.props;
        if (!viewport?.getBoundingClientRect || !rowRef?.getBoundingClientRect) return;
        const categoryTop = rowRef.getBoundingClientRect().top;
        const categoryBottom = rowRef.getBoundingClientRect().bottom;
        const { top, bottom } = viewport.getBoundingClientRect();

        if (categoryBottom > bottom) {
            viewport.scrollTop += categoryBottom - bottom + List.SCROLL_GAP;
        } else if (categoryTop < top) {
            viewport.scrollTop += categoryTop - top /*- List.SCROLL_GAP*/;
        }

        this.props.setThisListActive();
    }

    componentDidMount() {
        this.setState({ isLoaded: true });
    }

    render() {
        return (
            this.state.isLoaded && (
                <HorizontalList
                    ref={el => (this.horizontalList = el)}
                    className={'list' + (this.props.wrappedList ? ' list--wrapped' : '')}
                    onFocus={this.onFocusList}
                    retainLastFocus
                >
                    {this.props.items.map((item, i) => (
                        <ListItem
                            categoryIndex={this.props.categoryIndex + '-' + i}
                            goBack={this.props.goBack}
                            hGap={20}
                            horizontalAutoScroll
                            item={item}
                            itemIndex={i}
                            key={i}
                            navigation={this.props.navigation}
                            parentCategory={this.props.parentCategory}
                            parentList={this}
                            redirectTo={this.props.redirectTo}
                            ref={el => (this.itemsRef[i] = el)}
                            retainFocus
                            setThisItemActive={() => this.setItemActive(i)}
                            style={this.props.style}
                            viewport={this.props.viewport}
                            withProgression={this.props.withProgression}
                        />
                    ))}
                    {/* TODO Replace by ::after */}
                    <div className="list__gap" />
                </HorizontalList>
            )
        );
    }
}

export default List;

List.propTypes = {
    redirectTo: PropTypes.func,
    goBack: PropTypes.func,
    navigation: PropTypes.object,
    parentCategory: PropTypes.object,
    catIsInViewport: PropTypes.func,
    items: PropTypes.array,
    style: PropTypes.object,
    wrappedList: PropTypes.bool,
    withProgression: PropTypes.bool
};

/**
 * Default properties fot the List component
 * @type {{wrappedList: boolean}}
 */
List.defaultProps = {
    wrappedList: false,
    withProgression: false
};
