import React, { Component } from 'react';
import PropTypes from 'prop-types';
import { Spinner } from 'react-activity';
import 'react-activity/dist/library.css';

/**
 * This components is the loader display.
 *
 *
 **/
class Loader extends Component {
    /**
     * The size default value.
     **/
    static SIZE_DEFAULT = 45;

    /**
     * The speed default value.
     **/
    static SPEED_DEFAULT = 0.7;

    /**
     * The animation default value.
     **/
    static ANIMATION_DEFAULT = true;

    /**
     * Default color for spinner
     */
    static COLOR_DEFAULT = '#FBA00BFF';

    /**
     * Render the loader.
     *
     *
     */
    render() {
        const { className, ...restProps } = this.props;
        return (
            <div className={className || 'loader__wrapper'}>
                <Spinner {...restProps} />
            </div>
        );
    }
}

/**
 * @props string url The url of the button. Empty for a goBack.
 * @props string size The size of the button. Big or medium, medium by default.
 * @props string value The value of the button.
 **/
Loader.propTypes = {
    size: PropTypes.number,
    speed: PropTypes.number,
    animating: PropTypes.bool,
    color: PropTypes.string,
    className: PropTypes.string
};

Loader.defaultProps = {
    size: Loader.SIZE_DEFAULT,
    speed: Loader.SPEED_DEFAULT,
    animating: Loader.ANIMATION_DEFAULT,
    color: Loader.COLOR_DEFAULT
};

export default Loader;
