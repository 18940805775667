import { Component } from "react";

/**
 * This utils permit to manipulate string values.
 *
 *
 **/
class StringUtils extends Component {
  /**
   * The string type.
   */
  static TYPE_STRING = "string";

  /**
   * The undefined type.
   */
  static TYPE_UNDEFINED;

  /**
   * Check if a value is a string
   *
   * @param value
   * @return bool The result.
   *
   *
   */
  static isString(value) {
    return typeof value === StringUtils.TYPE_STRING;
  }

  /**
   * Capitalise a string.
   *
   * @param string string The string to be capitalized.
   * @return string The capitalized string.
   *
   *
   */
  static toCapitalize(string) {
    return string.charAt(0).toUpperCase() + string.slice(1).toLowerCase();
  }

  /**
   * Check if a string is empty
   *
   * @param string string The string to be checked.
   * @return bool The result of the test.
   *
   *
   **/
  static isEmpty(string) {
    return (
      string === null || string === StringUtils.TYPE_UNDEFINED || string === ""
    );
  }

  /**
   * Uppercase a string.
   *
   * @param string string The string to be uppercase.
   * @return string The uppercase string.
   *
   *
   */
  static toUpperCase(string) {
    return string.toUpperCase();
  }
}

export default StringUtils;
