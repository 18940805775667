import React from 'react';
import Image from 'components/Image.js';

const SplashScreen = () => (
    <div className={'splash-screen fade'}>
        <Image
            url={require('../assets/img/splash_screen.jpg')}
            alt={'Zone 300'}
            size={Image.SIZE_FULLSCREEN}
        />
    </div>
);

export default SplashScreen;
