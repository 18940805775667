import React, { createRef } from 'react';
import { Focusable } from 'components/Navigation';
import PropTypes from 'prop-types';

import Title, { LEVEL_4 } from 'components/Title';
import Text, { SIZE_SMALL } from 'components/Text';

const baseClass = 'season';

const getCssClasses = (focus, active) => {
    let classes = baseClass;
    classes += active ? ` ${baseClass}--active` : '';
    classes += focus ? ` ${baseClass}--focus` : '';
    return classes;
};

const Season = ({
    isActive,
    isFocus,
    navigation,
    parentOnClickHandler,
    parentOnEnterHandler,
    parentOnMouseEnterHandler,
    parentOnFocusHandler,
    season
}) => {
    let focusableRef = createRef();

    const onClickDownHandler = () => {
        navigation.current?.forceFocus(focusableRef.focusableId);
        parentOnClickHandler();
    };

    const onEnterDownHandler = () => parentOnEnterHandler();

    const onFocusHandler = (i, event) => parentOnFocusHandler();

    const onMouseEnterHandler = () => parentOnMouseEnterHandler();

    return (
        <Focusable
            ref={r => (focusableRef = r)}
            onMouseEnter={onMouseEnterHandler}
            onFocus={onFocusHandler}
            onClick={onClickDownHandler}
            onEnterDown={onEnterDownHandler}
        >
            <div className={getCssClasses(isFocus, isActive)}>
                <Title level={LEVEL_4}>{`Saison ${season.number}`}</Title>
                <Text size={SIZE_SMALL}>{`${season.episodes.length} épisodes`}</Text>
            </div>
        </Focusable>
    );
};

Season.propTypes = {
    isActive: PropTypes.bool,
    isFocus: PropTypes.bool,
    navigation: PropTypes.object,
    parentOnClickHandler: PropTypes.func,
    parentOnEnterHandler: PropTypes.func,
    parentOnFocusHandler: PropTypes.func,
    parentOnMouseEnterHandler: PropTypes.func,
    season: PropTypes.object.isRequired
};

Season.defaultProps = {};

export default Season;
