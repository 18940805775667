import React, { Component, createRef } from 'react';
import { connect } from 'react-redux';

import { Carousel } from '@cybergab/gab-react-responsive-carousel';
import '@cybergab/gab-react-responsive-carousel/lib/styles/carousel.min.css';
import { getImageUrl, typeCarousel, visual3 } from 'api/ImageManager';
import Text, { SIZE_LARGE } from './Text';
import { setCatalogLastFocus } from 'redux/actions/CatalogActions';

const largeur = '100%';
const hauteur = '35vh';

class GBCarousel extends Component {
    constructor(props) {
        super(props);

        this.state = {
            lastEventType: '',
            myRef: null,
            titleVisible: true,
            imageStatus: false
        };

        this.carousel = createRef();
    }

    /**
     * @TODO
     */
    handleImageLoaded(aff_titre) {
        if (aff_titre !== '1') {
            this.setState({ titleVisible: false });
        }
        this.setState({ imageStatus: true });
    }

    /**
     * @TODO
     */
    handleImageErrored() {
        this.setState({ imageStatus: false });
    }

    onClickItemHandler = item => {
        if ( this.props.focusableItem.focusableId ) this.props.setCatalogLastFocus( this.props.focusableItem.focusableId );
        this.props.redirectTo('/detail/' + item.transaction);
    };

    _renderMap(item, i) {
        return (
            <div
                key={item.transaction}
                style={{ width: largeur, height: hauteur }}
                onClick={this.onClickItemHandler}
            >
                <img
                    alt=""
                    style={{ width: largeur, height: hauteur, alignSelf: 'center' }}
                    src={getImageUrl(item.transaction, typeCarousel, visual3)}
                    onLoad={this.handleImageLoaded.bind(this, item.aff_titre)}
                    onError={this.handleImageErrored.bind(this)}
                />
                {this.state.titleVisible && (
                    <Text shadow={true} size={SIZE_LARGE}>
                        {item.titre}
                    </Text>
                )}
            </div>
        );
    }

    render() {
        return (
            <div className="carousel-wrapper">
                <Carousel
                    {...this.props}
                    ref={el => (this.carousel = el)}
                    onClickItem={(index, item) => {
                        if ( this.props.focusableItem.focusableId ) this.props.setCatalogLastFocus( this.props.focusableItem.focusableId );
                        this.props.redirectTo('/detail/' + item.key);
                    }}
                >
                    {this.props.images.map((img, i) => this._renderMap(img, i))}
                </Carousel>
            </div>
        );
    }
}

const stateToProps = state => ({});

const dispatchToProps = dispatch => ({
    setCatalogLastFocus: position => dispatch(setCatalogLastFocus(position)),
});

export default connect(stateToProps, dispatchToProps, null, { forwardRef: true })(GBCarousel);
