import React, { useMemo } from 'react';
import PropTypes from 'prop-types';

export const LEVEL_1 = '1';
export const LEVEL_2 = '2';
export const LEVEL_3 = '3';
export const LEVEL_4 = '4';
export const LEVEL_5 = '5';
export const LEVEL_6 = '6';
export const COLOR_PRIMARY = 'primary';
export const COLOR_SECONDARY = 'secondary';
export const COLOR_GREY = 'grey';
export const FONT_WEIGHT_LIGHT = 'light';
export const FONT_WEIGHT_BOLD = 'bold';
const baseClass = 'title';

const getCssClasses = (className, level, color, light) => {
    let classes = baseClass + (className ? ` ${className}` : '');

    switch (level) {
        case LEVEL_2:
            classes += ` ${baseClass}--${LEVEL_2}`;
            break;
        case LEVEL_3:
            classes += ` ${baseClass}--${LEVEL_3}`;
            break;
        case LEVEL_4:
            classes += ` ${baseClass}--${LEVEL_4}`;
            break;
        case LEVEL_5:
            classes += ` ${baseClass}--${LEVEL_5}`;
            break;
        case LEVEL_6:
            classes += ` ${baseClass}--${LEVEL_6}`;
            break;
        case LEVEL_1:
        default:
            classes += ` ${baseClass}--${LEVEL_1}`;
            break;
    }

    switch (color) {
        case COLOR_SECONDARY:
            classes += ` ${baseClass}--${COLOR_SECONDARY}`;
            break;
        case COLOR_GREY:
            classes += ` ${baseClass}--${COLOR_GREY}`;
            break;
        case COLOR_PRIMARY:
        default:
            classes += ` ${baseClass}--${COLOR_PRIMARY}`;
            break;
    }

    switch (color) {
        case FONT_WEIGHT_LIGHT:
            classes += ` ${baseClass}--${FONT_WEIGHT_LIGHT}`;
            break;
        case FONT_WEIGHT_BOLD:
        default:
            classes += ` ${baseClass}--${FONT_WEIGHT_BOLD}`;
            break;
    }

    return classes;
};

const Title = ({ className, children, level, color, fontWeight }) => {
    const TitleTag = useMemo(() => {
        switch (level) {
            case LEVEL_2:
                return 'h2';
            case LEVEL_3:
                return 'h3';
            case LEVEL_4:
                return 'h4';
            case LEVEL_5:
                return 'h5';
            case LEVEL_6:
                return 'h6';
            case LEVEL_1:
            default:
                return 'h1';
        }
    }, [level]);

    return (
        <TitleTag className={getCssClasses(className, level, color, fontWeight)}>
            {children}
        </TitleTag>
    );
};

Title.propTypes = {
    level: PropTypes.oneOf([LEVEL_1, LEVEL_2, LEVEL_3, LEVEL_4, LEVEL_5, LEVEL_6]),
    color: PropTypes.oneOf([COLOR_PRIMARY, COLOR_SECONDARY, COLOR_GREY]),
    fontWeight: PropTypes.string,
    className: PropTypes.string
};

Title.defaultProps = {
    color: COLOR_PRIMARY
};

export default Title;
