import React from 'react';
import { BrowserRouter, Route, Switch } from 'react-router-dom';
import { LastLocationProvider } from 'react-router-last-location';

import useAuth from 'hooks/useAuth';
import Landing from 'pages/Landing';
import Login from 'pages/Login';
import Details from '../components/PageDetails';
// import PlayerSafe from 'components/PlayerFireStick';
// import MyPlayer from 'components/Player/Player';
import Catalog from 'pages/Catalog';
import Search from 'pages/Search';
import Subscription from 'pages/Subscription';
import SplashScreen from 'components/SplashScreen';
import {
    customHistory,
    goTo,
    PATH_ROOT,
    PATH_HOME,
    PATH_LOGIN,
    PATH_EXIT,
    PATH_SUBSCRIBE,
    PATH_SEARCH,
    PATH_DETAIL,
    PATH_PLAY,
    PATH_PARAMS_FILM_ID,
    PATH_LANDING
} from 'utils/NavigationUtils';
import { SystemContext } from 'contexts';
import CustomPlayer from 'components/Player';

const hostname = window.location.hostname.replace('.zone300.tv', '');

const Router = () => {
    const { isAuthenticated, isInitialized } = useAuth();
    return !isInitialized ? (
        <SplashScreen parent={this} />
    ) : (
        <BrowserRouter>
            <LastLocationProvider>
                <SystemContext.Consumer>
                    {systemValues => (
                        <Switch>
                            <Route
                                exact
                                path={PATH_ROOT}
                                render={props => {
                                    if (!isAuthenticated) {
                                        goTo(props.history, PATH_LANDING, true);
                                    } else {
                                        goTo(props.history, PATH_HOME, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_LANDING}
                                render={props => {
                                    if (!isAuthenticated) {
                                        return (
                                            <Landing {...props} hostname={systemValues.system} />
                                        );
                                    } else {
                                        if (customHistory.length > 0) {
                                            goTo(props.history, PATH_EXIT, true);
                                        } else {
                                            goTo(props.history, PATH_HOME);
                                        }
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_SUBSCRIBE}
                                render={props => (
                                    <Subscription {...props} hostname={systemValues.system} />
                                )}
                            />
                            <Route
                                exact
                                path={PATH_LOGIN}
                                render={props => {
                                    if (!isAuthenticated) {
                                        return <Login {...props} app={this} />;
                                    } else {
                                        goTo(props.history, PATH_HOME, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_HOME}
                                render={props => {
                                    if (isAuthenticated) {
                                        return (
                                            <Catalog
                                                {...props}
                                                app={this}
                                                // sidebarExit={customHistory.length > 0}
                                                sidebarExit={false}
                                            />
                                            // TODO condition on custom history context
                                        );
                                    } else {
                                        goTo(props.history, PATH_LOGIN, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_SEARCH}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <Search {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LOGIN, true);
                                    }
                                }}
                            />
                            <Route
                                path={PATH_EXIT}
                                render={props => {
                                    if (['amazon', 'android'].includes(systemValues.system)) window.closeApp();
                                    window.close();
                                }}
                            />
                            <Route
                                exact
                                path={PATH_DETAIL + PATH_PARAMS_FILM_ID}
                                render={props => {
                                    if (isAuthenticated) {
                                        return <Details {...props} />;
                                    } else {
                                        goTo(props.history, PATH_LOGIN, true);
                                    }
                                }}
                            />
                            <Route
                                exact
                                path={PATH_PLAY + PATH_PARAMS_FILM_ID}
                                render={props => {
                                    if (isAuthenticated) {
                                        return (
                                            <CustomPlayer {...props} hostname={hostname} />
                                            // <MyPlayer
                                            //     {...props}
                                            //     hostname={hostname}
                                            //     systemContext={systemValues}
                                            //     isAndroid={['amazon', 'android'].includes(
                                            //         systemValues.system
                                            //     )}
                                            // />
                                        );
                                    } else {
                                        goTo(props.history, PATH_LOGIN, true);
                                    }
                                }}
                            />
                        </Switch>
                    )}
                </SystemContext.Consumer>
            </LastLocationProvider>
        </BrowserRouter>
    );
};

export default Router;
