import React, { Component } from 'react';
import { VerticalList } from 'components/Navigation';
import Navigation from 'components/Navigation';

import { AuthContext } from 'contexts';

import Wrapper from 'components/Wrapper';
import Input from 'components/Input';
import Button, { SIZE_GIANT as BUTTON_GIANT, BLOCK_FULL as BUTTON_FULL } from 'components/Button';
import Title, { LEVEL_1, LEVEL_2, COLOR_SECONDARY, LEVEL_3 } from 'components/Title';
import Text, { COLOR_ERROR, ALIGN_CENTER, SIZE_LARGE as TEXT_LARGE } from 'components/Text';
import Image from 'components/Image';
import { goBack, goTo, isReturnEvent, PATH_HOME } from 'utils/NavigationUtils';

/**
 * The login page.
 */
class Login extends Component {
    /**
     * The navigation object.
     */
    navigation = null;

    static MESSAGE_ERROR_DISCARDED = 'Votre compte est désactivé. Rendez vous sur zone300.com pour le réactiver.';

    /**
     * The error message for empty email.
     */
    static MESSAGE_ERROR_EMAIL = "l'adresse Email doit être renseignée";

    /**
     * The error message for empty password.
     */
    static MESSAGE_ERROR_PASSWORD = 'le mot de passe doit être renseigné';

    /**
     * The error message for bad password / id.
     */
    static MESSAGE_ERROR_VALUES = 'email et/ou mot de passe incorrect';

    /**
     * Set the isLoaded state to false.
     * @param Object props
     */
    constructor(props) {
        super(props);

        this.state = {
            messageError: '',
            isLoaded: false,
            email: '',
            password: ''
        };

        this.verifyUser = this.verifyUser.bind(this);
        this.keyDownListener = this.keyDownListener.bind(this);
        this.handleInputChange = this.handleInputChange.bind(this);
    }

    isFormValid() {
        if (this.state.email.trim() === '') {
            this.setState({ messageError: Login.MESSAGE_ERROR_EMAIL });
            return false;
        } else if (this.state.password.trim() === '') {
            this.setState({ messageError: Login.MESSAGE_ERROR_PASSWORD });
            return false;
        }
        return true;
    }

    /**
     * Do the user connection.
     */
    async verifyUser() {
        this.setState({ messageError: '' });
        if ( this.isFormValid() ) {
            this.context
                .login( this.state.email, this.state.password )
                .then( res => {
                    if ( res.sub ) {
                        goTo(this.props.history, PATH_HOME, true);
                    } else if ( res.error === 'discarded' ) {
                        this.setState({ messageError: Login.MESSAGE_ERROR_DISCARDED });
                    } else {
                        this.setState({ messageError: Login.MESSAGE_ERROR_VALUES });
                    }
                })
                .catch( err => {
                    this.setState({ messageError: Login.MESSAGE_ERROR_VALUES });
                });
        }
    }

    /**
     * Open sidebar on specified item focus
     * @param evnt
     */
    keyDownListener(evnt) {
        if (isReturnEvent(evnt)) {
            goBack(this.props.history);
        }
    }

    handleInputChange(event) {
        if (this.state.messageError) {
            this.setState({ messageError: '' });
        }
        const { value, name } = event.target;
        switch (name) {
            case 'password':
                this.setState({ password: value });
                break;
            case 'email':
                this.setState({ email: value });
                break;
            default:
                break;
        }
    }

    /**
     * Check if navigation is not null to avoid error.
     */
    componentDidMount() {
        if (this.navigation instanceof Navigation) {
            this.setState({ isLoaded: true });
        }
        window.addEventListener('keydown', this.keyDownListener);
    }

    /**
     * Remove event listener
     */
    componentWillUnmount() {
        window.removeEventListener('keydown', this.keyDownListener);
    }

    render() {
        return (
            <Navigation ref={el => (this.navigation = el)}>
                <VerticalList className="login">
                    <Image
                        url={require('../assets/img/grille-hd.jpg')}
                        size={Image.SIZE_FULLSCREEN}
                    />
                    <Wrapper overlay={Wrapper.OVERLAY_GRADIENT_BACK}>
                        <Title level={LEVEL_1}>
                            <Image alt="Zone 300" url={require('../assets/img/logo-24.png')} />
                        </Title>
                        <Title level={LEVEL_2} color={COLOR_SECONDARY}>
                            Se connecter
                        </Title>
                        <Title level={LEVEL_3}>
                            Renseigne ton email et ton mot de passe et accède à tes films de chasse,
                            pêche préférés !
                        </Title>
                        <Wrapper position={Wrapper.POSITION_CENTERED}>
                            <Input
                                type="text"
                                label="Email"
                                name="email"
                                size={Input.SIZE_BIG}
                                navigation={this.state.isLoaded ? this.navigation : null}
                                autoFocus={true}
                                tabIndex={1}
                                onChange={this.handleInputChange}
                                value={this.state.email}
                            />
                            <Input
                                type="password"
                                label="Mot de passe"
                                name="password"
                                size={Input.SIZE_BIG}
                                navigation={this.state.isLoaded ? this.navigation : null}
                                tabIndex={2}
                                onChange={this.handleInputChange}
                                value={this.state.password}
                            />
                            {this.state.messageError && (
                                <Text state={COLOR_ERROR} align={ALIGN_CENTER}>
                                    {this.state.messageError}
                                </Text>
                            )}
                        </Wrapper>
                    </Wrapper>
                    <Wrapper sticky={Wrapper.STICKY_BOTTOM} padding={Wrapper.PADDING_NONE}>
                        <Button
                            navigation={this.state.isLoaded ? this.navigation : null}
                            block={BUTTON_FULL}
                            url={() => this.verifyUser()}
                            history={this.props.history}
                            size={BUTTON_GIANT}
                        >
                            <Text size={TEXT_LARGE}>Go !</Text>
                        </Button>
                    </Wrapper>
                </VerticalList>
            </Navigation>
        );
    }
}

Login.contextType = AuthContext;

export default Login;
