import React, { Component } from 'react';
import { connect } from 'react-redux';
import { VerticalList } from 'components/Navigation';
import MenuItem from './MenuItem';
import SvgIcon from './SvgIcon';
import { goTo, PATH_EXIT, PATH_HOME, PATH_LOGOUT, PATH_SEARCH } from 'utils/NavigationUtils';
import { AuthContext } from 'contexts';
import { setThemeActive } from 'redux/actions/CatalogActions';

import { ReactComponent as Home } from 'assets/icons/icon-home.svg';
import { ReactComponent as Hunting } from 'assets/icons/icon-hunt-deer.svg';
import { ReactComponent as Fishing } from 'assets/icons/icon-fishing-fish.svg';
import { ReactComponent as Search } from 'assets/icons/icon-search.svg';
import { ReactComponent as Logout } from 'assets/icons/icon-log-out.svg';
import { ReactComponent as Quit } from 'assets/icons/icon-close.svg';

class Menu extends Component {
    /**
     * Array containing data for the sidebar items
     * @type {[{ref: string, icon: string, keyId: string, text: string, url: string}]}
     */
    static ITEMS = [
        {
            text: 'Mon Zone300',
            ref: 'monz3',
            keyId: 'monz3',
            icon: Home,
            url: PATH_HOME,
            replaceUrl: true
        },
        {
            text: 'Chasse',
            ref: 'chasse',
            keyId: 'chasse',
            icon: Hunting,
            url: PATH_HOME,
            replaceUrl: true,
            indentLvl: 1
        },
        {
            text: 'Pêche',
            ref: 'peche',
            keyId: 'peche',
            icon: Fishing,
            url: PATH_HOME,
            replaceUrl: true,
            indentLvl: 1
        },
        {
            text: 'Recherche',
            ref: 'search',
            keyId: 'search',
            icon: Search,
            url: PATH_SEARCH,
            replaceUrl: false,
            separator: true
        },
        {
            text: 'Déconnexion',
            ref: 'disconnect',
            keyId: 'disconnect',
            icon: Logout,
            url: PATH_LOGOUT,
            replaceUrl: true
        },
        {
            text: 'Quitter',
            ref: 'quit',
            keyId: 'quit',
            icon: Quit,
            url: PATH_EXIT,
            replaceUrl: true,
            minVersion: '210511' //min version for display in Android and Fire TVs
        }
    ];

    /**
     * Constructor method of the Menu component
     * @param props
     */
    constructor(props) {
        super(props);

        this.onGo = this.onGo.bind(this);
    }

    /**
     * Use only state to define Categories state theme
     * @param item
     */
    onGo(item) {
        if (item.url === PATH_LOGOUT) {
            this.context.logout();
        } else if (window.location.pathname === item.url && item.url === PATH_HOME) {
            this.props.setThemeActive(item.keyId);
            goTo(this.props.history, item.url, item.replaceUrl);
        } else {
            goTo(this.props.history, item.url, item.replaceUrl);
        }
        this.props.closeSidebar();
    }

    render() {
        const { themes, themeActive } = this.props;
        return (
            <ul className="menu">
                <div className="menu__close">
                    {Menu.ITEMS.filter(
                        item =>
                            !item.minVersion ||
                            !window.getAndroidCapsuleVersion() ||
                            window.getAndroidCapsuleVersion() >= item.minVersion
                    ).map((item, i) => (
                        <SvgIcon
                            key={i}
                            icon={item.icon}
                            separator={item.separator}
                            selected={themes[themeActive] === item.keyId}
                        />
                    ))}
                </div>
                <VerticalList
                    className="menu__open"
                    ref={el => (this.menuVList = el)}
                    onFocus={() => this.props.openSidebar()}
                    onBlur={() => this.props.closeSidebar()}
                >
                    {Menu.ITEMS.filter(
                        item =>
                            !item.minVersion ||
                            !window.getAndroidCapsuleVersion() ||
                            window.getAndroidCapsuleVersion() >= item.minVersion
                    ).map((item, i) => (
                        <MenuItem
                            key={i}
                            ref={el => (this[item.ref] = el)}
                            goTo={() => this.onGo(item)}
                            keyId={item.keyId}
                            icon={item.icon}
                            navigation={this.props.navigation}
                            parentSidebar={this}
                            selected={themes[themeActive] === item.keyId}
                            visible={this.props.visible}
                            separator={item.separator}
                        >
                            {item.text}
                        </MenuItem>
                    ))}
                </VerticalList>
            </ul>
        );
    }
}

Menu.contextType = AuthContext;

const stateToProps = state => ({
    themeActive: state.catalog.themeActive,
    themes: state.catalog.themes
});

const dispatchToProps = dispatch => ({
    setThemeActive: theme => dispatch(setThemeActive(theme))
});

export default connect(stateToProps, dispatchToProps, null, { forwardRef: true })(Menu);
