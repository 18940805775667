import React, { useState } from 'react';
import PropTypes from 'prop-types';

import { Focusable } from 'components/Navigation';

const baseClass = 'control';
const getClasses = (className, isFocus) => {
    let classes = (className ? `${className} ` : '') + baseClass;

    classes += isFocus ? ` ${baseClass}--focus` : '';
    return classes;
};

const ControlFocusable = ({ action, children, className, defaultFocus, onFocus, onBlur }) => {
    const [focused, setFocused] = useState(defaultFocus);

    const onMouseEnterHandler = () => {};

    const onFocusHandler = (i, event) => {
        if (typeof onFocus === 'function') {
            onFocus(i, event);
        }
        setFocused(true);
    };

    const onBlurHandler = () => {
        if (typeof onBlur === 'function') {
            onBlur();
        }

        setFocused(false);
    };

    const onEnterDownHandler = () => {
        if (typeof action === 'function') {
            action();
        }
    };

    const onClickHandler = () => {
        if (typeof action === 'function') {
            action();
        }
    };

    return (
        <Focusable
            className={getClasses(className, focused)}
            onMouseEnter={onMouseEnterHandler}
            onFocus={onFocusHandler}
            onBlur={onBlurHandler}
            onEnterDown={onEnterDownHandler}
            onClick={onClickHandler}
            navDefault={defaultFocus}
        >
            {children}
        </Focusable>
    );
};

ControlFocusable.propTypes = {
    action: PropTypes.func,
    children: PropTypes.oneOfType([PropTypes.node, PropTypes.array]),
    defaultFocus: PropTypes.bool,
    onFocus: PropTypes.func,
    onBlur: PropTypes.func
};

ControlFocusable.defaultProps = {
    defaultFocus: false
};

export default ControlFocusable;
