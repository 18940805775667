import React, { useMemo, createRef, useEffect, useState, useCallback } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useParams, useHistory } from 'react-router-dom';
// import ReactHtmlParser from 'react-html-parser';
import { HorizontalList } from 'components/Navigation';

import useAuth from 'hooks/useAuth';
import Button, {
    SIZE_BIG as BUTTON_BIG,
    STYLE_OUTLINE as BUTTON_OUTLINE,
    SIZE_ROUND as BUTTON_ROUND
} from 'components/Button';
import Title, { LEVEL_1, LEVEL_4 } from 'components/Title';
import Text, { SIZE_SMALL as TEXT_SMALL } from 'components/Text';
import { PATH_PLAY } from 'utils/NavigationUtils';
import Image from 'components/Image';
import SvgIcon from 'components/SvgIcon';
import { getFullCatalogNew, addMovieToFavorites, setMovie } from 'redux/actions/CatalogActions';
import { getApiAdminUrl } from 'api/ApiManager';
import { ReactComponent as IconAdd } from 'assets/icons/icon-add.svg';
import { ReactComponent as IconCheck } from 'assets/icons/icon-check.svg';
import { ReactComponent as IconPlay } from 'assets/icons/icon-play.svg';
import { ReactComponent as CSA10 } from 'assets/icons/icon-csa-10.svg';
import { ReactComponent as CSA12 } from 'assets/icons/icon-csa-12.svg';
import { ReactComponent as CSA16 } from 'assets/icons/icon-csa-16.svg';
import { ReactComponent as CSA18 } from 'assets/icons/icon-csa-18.svg';
import { ReactComponent as CSAAll } from 'assets/icons/icon-csa-all.svg';
import { ReactComponent as DefHD } from 'assets/icons/icon-hd.svg';
import { ReactComponent as DefUHD } from 'assets/icons/icon-4k.svg';
import { ReactComponent as Def43 } from 'assets/icons/icon-4-3.svg';
import { goTo } from 'utils/NavigationUtils';

// TODO
//      add casting, vote thumbs
//      if progress: nb episodes, localization

const DetailsInformation = ({ navigation, movie, isVisible, setVisible }) => {
    const dispatch = useDispatch();
    const history = useHistory();
    const { user } = useAuth();
    const { rowGroups, themes, themeActive } = useSelector(state => state.catalog);
    const { FilmID: filmId } = useParams();
    const infosRef = createRef();
    const [isInFavorite, setIsInFavorite] = useState(false);

    const titlePng = useMemo(
        () =>
            movie?.titlePngFileName
                ? `${getApiAdminUrl}cimage/webroot/img.php?src=../../../DATA/TMP/${movie.titlePngFileName}.png&h=160&crop-to-fit`
                : '',
        [movie]
    );

    const CsaIcon = useMemo(() => {
        switch (movie?.csa) {
            case 'csa10':
                return CSA10;
            case 'csa12':
                return CSA12;
            case 'csa16':
                return CSA16;
            case 'csa18':
                return CSA18;
            default:
                return CSAAll;
        }
    }, [movie]);

    const DefinitionIcon = useMemo(() => {
        switch (movie?.definition) {
            case 'high-definition':
                return DefHD;
            case '4k':
                return DefUHD;
            case '4-3':
                return Def43;
            default:
                return null;
        }
    }, [movie]);

    const episodeToStart = useMemo(() => {
        if (!movie?.episodeToStartIndexes) return null;
        const { seasonIndex, episodeIndex } = movie.episodeToStartIndexes;
        const episode = movie.seasons[seasonIndex].episodes[episodeIndex];

        return {
            ...episode,
            seasonIndex,
            episodeIndex,
            timePercentage: Math.floor((+episode.time * 100) / +episode.timeTotal)
        };
    }, [movie]);

    // const episodePosition = useMemo(() => {
    //     if (!movie?.episodeToStartIndexes) return null;

    //     const { seasonIndex, episodeIndex } = movie.episodeToStartIndexes;
    //     return {
    //         ...moviePosition,
    //         seasonIndex,
    //         episodeIndex
    //     };
    // }, [movie, moviePosition]);

    const onFocusBtnHandler = e => {
        if (!isVisible) {
            setVisible(true);
        }
    };

    const favoriteBtnAction = isInFavorite => {
        dispatch(addMovieToFavorites(isInFavorite, movie, user.NoCli));
    };

    const calcDisplayTimeLeft = useCallback(() => {
        if (!episodeToStart) return null;

        const { timeTotal, time } = episodeToStart;
        let secondsLeft = timeTotal - time;
        let minutesLeft = 0;
        let hoursLeft = 0;

        if (secondsLeft >= 60) {
            minutesLeft = Math.floor(secondsLeft / 60);
            secondsLeft = secondsLeft % 60;
        }

        if (minutesLeft >= 60) {
            hoursLeft = Math.floor(minutesLeft / 60);
            minutesLeft = minutesLeft % 60;
        }

        if (hoursLeft > 0 || minutesLeft > 0) {
            return (
                (hoursLeft > 0 ? `${hoursLeft} h ` : '') +
                (minutesLeft > 0 ? `${minutesLeft} min` : '') +
                ' restantes'
            );
        }
    }, [episodeToStart]);

    const seriesPlayBtnAction = () => {
        if (!episodeToStart?.transaction) return;

        const { seasonIndex, episodeIndex } = movie.episodeToStartIndexes;
        const nextEpisodes = movie.seasons[seasonIndex].episodes.slice(episodeIndex + 1);

        dispatch(setMovie(episodeToStart, nextEpisodes));
        goTo(history, PATH_PLAY + '/' + episodeToStart.transaction);
    };

    useEffect(() => {
        if (!rowGroups.favorites) {
            dispatch(getFullCatalogNew(themes[themeActive], user.NoCli));
        } else {
            let isInFav = false;
            for (const movie of rowGroups.favorites[0].movies) {
                if (movie.transaction === filmId) {
                    isInFav = true;
                    break;
                }
            }
            setIsInFavorite(isInFav);
        }
    }, [rowGroups, dispatch, themes, themeActive, user.NoCli, filmId]);

    return (
        <div className="details__infos" ref={infosRef}>
            {movie &&
                (titlePng ? (
                    <Image
                        className="details__infos__title-img"
                        url={titlePng}
                        onError={e => (e.target.style.display = 'none')}
                    />
                ) : (
                    <Title level={LEVEL_1}>{movie.titre}</Title>
                ))}
            <HorizontalList className="details__infos__btns">
                {movie?.type === 'series' ? (
                    <Button
                        parentOnFocusHandler={onFocusBtnHandler}
                        navigation={navigation}
                        size={BUTTON_BIG}
                        style={BUTTON_OUTLINE}
                        focusOnLoad={true}
                        url={seriesPlayBtnAction}
                        history={history}
                    >
                        <SvgIcon icon={IconPlay} height="2.41vh" width="2.41vh" />
                        <Text>
                            {episodeToStart?.time <= 0 || !episodeToStart?.timeTotal
                                ? 'Lecture'
                                : 'Reprendre'}
                        </Text>
                    </Button>
                ) : (
                    <Button
                        parentOnFocusHandler={onFocusBtnHandler}
                        navigation={navigation}
                        size={BUTTON_BIG}
                        style={BUTTON_OUTLINE}
                        focusOnLoad={true}
                        url={PATH_PLAY + '/' + filmId}
                        history={history}
                    >
                        <SvgIcon icon={IconPlay} height="2.41vh" width="2.41vh" />
                        <Text>Lecture</Text>
                    </Button>
                )}
                {movie?.transaction && (
                    <>
                        {/* TODO PATH_PLAY add trailer to path or pass video filename in path params ?  */}
                        {movie?.trailer && (
                            <Button
                                parentOnFocusHandler={onFocusBtnHandler}
                                navigation={navigation}
                                size={BUTTON_BIG}
                                style={BUTTON_OUTLINE}
                                url={`${PATH_PLAY}/${filmId}?type=trailer`}
                                history={history}
                            >
                                <Text>Bande annonce</Text>
                            </Button>
                        )}
                        <Button
                            parentOnFocusHandler={onFocusBtnHandler}
                            navigation={navigation}
                            size={BUTTON_ROUND}
                            style={BUTTON_OUTLINE}
                            url={() => favoriteBtnAction(isInFavorite)}
                            history={history}
                        >
                            {isInFavorite ? (
                                <SvgIcon icon={IconCheck} height="2.9vh" width="2.9vh" />
                            ) : (
                                <SvgIcon icon={IconAdd} height="2.9vh" width="2.9vh" />
                            )}
                        </Button>
                    </>
                )}
            </HorizontalList>
            {movie && (
                <>
                    {(episodeToStart?.episodeIndex > 0 || episodeToStart?.seasonIndex > 0) && (
                        <div className="details__infos__progress">
                            <Title level={LEVEL_4}>{episodeToStart.titre}</Title>
                            {episodeToStart?.time > 0 && episodeToStart?.timeTotal && (
                                <div className="details__infos__progress__bar">
                                    <span className="details__infos__progress__bar--bar">
                                        <span
                                            className="details__infos__progress__bar--progression"
                                            style={{
                                                width: `${episodeToStart.timePercentage}%`
                                            }}
                                        />
                                    </span>
                                    <Text size={TEXT_SMALL}>{calcDisplayTimeLeft()}</Text>
                                </div>
                            )}
                        </div>
                    )}
                    {movie?.categories?.length > 0 && (
                        <div className="details__infos__categories">
                            {movie.categories.map((category, i) => (
                                <Text size={TEXT_SMALL} key={i}>
                                    {category.name}
                                </Text>
                            ))}
                        </div>
                    )}
                    <Text className="details__infos__synopsis">{movie.synopsis_txt}</Text>
                    <div className="details__infos__meta">
                        {movie.shootingDate && <Text size={TEXT_SMALL}>{movie.shootingDate}</Text>}
                        {CsaIcon && <SvgIcon icon={CsaIcon} height="1.85vh" />}
                        {DefinitionIcon && <SvgIcon icon={DefinitionIcon} height="1.85vh" />}
                    </div>
                </>
            )}
        </div>
    );
};

DetailsInformation.propTypes = {
    navigation: PropTypes.object,
    movie: PropTypes.object,
    isVisible: PropTypes.bool,
    setVisible: PropTypes.func
};

DetailsInformation.defaultProps = {};

export default DetailsInformation;
