export const typeThumb = 1;
export const typeFullscreen = 2;
export const typeCarousel = 3;
export const typeCarouselFullscreen = 4;
export const typePoster = 5;
export const typeSquare = 8;

export const visual1 = 'VISUEL';
export const visual2 = 'VISUEL2';
export const visual3 = 'VISUEL3';
export const visual4 = 'VISUEL4';
export const visual5 = 'VISUEL5'; // poster
export const visual8 = 'VISUEL8'; // square
export const visual10 = 'VISUEL10'; // posterLong
/**
 * Returns the URL to retrieve an image
 * @param filmId
 * @param type
 * @param visual
 * @returns {string}
 * @constructor
 */
export function getImageUrl(filmId, type, visual = visual1) {
    // let url = getApiAdminUrl + 'cimage/webroot/img.php?src=../../../DATA/TMP/';
    let url = 'https://manager.zone300.com/api/cimage/webroot/img.php?src=';
    url += type === typeCarouselFullscreen ? 'img-tv/' : filmId + '-' + visual + '.jpg';

    switch (type) {
        case typeFullscreen:
            url += `&w=${window.innerWidth}&h=${window.innerHeight}&crop-to-fit`;
            break;
        case typeCarousel:
            url += '&h=720&crop-to-fit';
            break;
        case typeCarouselFullscreen:
            url += filmId;
            break;
        case typePoster:
            url += '&h=500&crop-to-fit';
            break;
        case typeSquare:
            url += '&w=500&h=500&crop-to-fit';
            break;
        case typeThumb:
        default:
            url += '&h=200&crop-to-fit';
    }

    return url;
}
