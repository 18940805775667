import React from 'react';
import PropTypes from 'prop-types';
import CssClassUtils from 'utils/CssClassUtils';

const CLASS_TEXT = 'text';

export const ALIGN_CENTER = 'center';
export const CLASS_ALIGN_CENTER = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + ALIGN_CENTER;

export const ALIGN_JUSTIFY = 'justify';
export const CLASS_ALIGN_JUSTIFY = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + ALIGN_JUSTIFY;

export const COLOR_GREY = 'grey';
export const CLASS_COLOR_GREY = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + COLOR_GREY;

export const COLOR_SECONDARY = 'secondary';
export const CLASS_COLOR_SECONDARY =
    CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + COLOR_SECONDARY;

export const SHADOW = 'shadow';
export const CLASS_SHADOW = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + SHADOW;

export const SIZE_LARGE = 'large';
export const CLASS_SIZE_LARGE = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + SIZE_LARGE;

export const SIZE_SMALL = 'small';
export const CLASS_SIZE_SMALL = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + SIZE_SMALL;

export const SIZE_XS = 'xs';
export const CLASS_SIZE_XS = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + SIZE_XS;

export const SIZE_XXS = 'xxs';
export const CLASS_SIZE_XXS = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + SIZE_XXS;

export const COLOR_ERROR = 'error';
export const CLASS_STATE_ERROR = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + COLOR_ERROR;

export const COLOR_SUCCESS = 'success';
export const CLASS_STATE_SUCCESS = CLASS_TEXT + CssClassUtils.CLASS_MOD_SEPARATOR + COLOR_SUCCESS;

const getCssClasses = (className, state, align, color, shadow, size) => {
    let classes = CLASS_TEXT + (className ? ` ${className}` : '');

    switch (state) {
        case COLOR_ERROR:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_STATE_ERROR;
            break;
        case COLOR_SUCCESS:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_STATE_SUCCESS;
            break;
        default:
            break;
    }

    switch (align) {
        case ALIGN_CENTER:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_ALIGN_CENTER;
            break;
        case ALIGN_JUSTIFY:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_ALIGN_JUSTIFY;
            break;
        default:
            break;
    }

    switch (color) {
        case COLOR_SECONDARY:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_COLOR_SECONDARY;
            break;
        case COLOR_GREY:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_COLOR_GREY;
            break;
        default:
            break;
    }

    if (shadow) {
        classes += CssClassUtils.CLASS_SEPARATOR + CLASS_SHADOW;
    }

    switch (size) {
        case SIZE_SMALL:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_SIZE_SMALL;
            break;
        case SIZE_LARGE:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_SIZE_LARGE;
            break;
        case SIZE_XS:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_SIZE_XS;
            break;
        case SIZE_XXS:
            classes += CssClassUtils.CLASS_SEPARATOR + CLASS_SIZE_XXS;
            break;
        default:
            break;
    }
    return classes;
};

const Text = ({ state, align, color, shadow, size, children, className, ...restProps }) => (
    <p className={getCssClasses(className, state, align, color, shadow, size)} {...restProps}>
        {children}
    </p>
);

Text.propTypes = {
    align: PropTypes.oneOf([ALIGN_CENTER, ALIGN_JUSTIFY]),
    children: PropTypes.oneOfType([PropTypes.string, PropTypes.node, PropTypes.array]),
    state: PropTypes.oneOf([COLOR_ERROR, COLOR_SUCCESS]),
    color: PropTypes.oneOf([COLOR_SECONDARY, COLOR_GREY]),
    shadow: PropTypes.bool,
    size: PropTypes.oneOf([SIZE_XS, SIZE_SMALL, SIZE_LARGE, SIZE_XXS])
};

Text.defaultProps = {};

export default Text;
